// eslint-disable-next-line no-unused-vars
import { postApi, getApi, deleteApi } from "./Utils/ApiMethods";

const LOGIN = "/adminLogin";
// eslint-disable-next-line no-unused-vars
const userData = "/userDetail";
const LISTUSERS = "/allUsers";
const REPORTEDUSERS = "/reportedUsers";
const DELETEDUSERS = "/deletedUsers";
const USER_DETAIL = "/userDetail";
const ACTIVEUSERS = "/activeUsers";
const BLOCKEDUSERS = "/blockedUsers";
const DELETEUSERS = "/deleteAccount";
const SEARCH_DASHBORD = "/searchDashboard";
const SEARCH_ACTIVEUSER = "/searchActiveUsers";
const SEARCH_BLOCKEDUSER = "/searchBlockedUsers";
const SEARCHALLUSERS = "/searchApiAllUsers";
const SEARCHDELETEUSER = "/seachDeletedUsers";
const SEARCHREPORTEDUSER = "/searchReportedUser";
const BLOCKUSER = "/blockUser";
const UNBLOCKUSER = "/unblockUser";
const EDITUSERINFO = "/editProfile";
const DELETEIMAGE = "/deleteImage";
const DASHBOARD = "/dashboard";
const JIGARTALA_USER = "/allUsersEnabledByAdmin";
const SEARCH_JIGARTALA_USER = "/searchUsersEnabledByAdmin";
const CANCEL_SUBCRIPTIION = "/removeSubscriptionByAdmin";
const GET_USER_ALL_IMAGES = "/getUserImages";

export const login = (email, password) => {
  return postApi(LOGIN, { email, password });
};

export const listUsers = (limit, page, data) => {
  return postApi(`${LISTUSERS}?limit=${limit}&page=${page}`, data);
};

export const reportedUsers = (reported_limit, page) => {
  return getApi(`${REPORTEDUSERS}?limit=${reported_limit}&page=${page}`);
};

export const viewUserDetail = (id) => {
  return getApi(`${USER_DETAIL}?id=${id}`);
};
export const deletedUsers = (del_limit, del_page, data) => {
  return postApi(`${DELETEDUSERS}?limit=${del_limit}&page=${del_page}`, data);
};
export const activeUsers = (active_limit, active_page, data) => {
  return postApi(
    `${ACTIVEUSERS}?limit=${active_limit}&page=${active_page}`,
    data
  );
};
export const blockedUsers = (blocked_limit, blocked_page, data) => {
  return postApi(
    `${BLOCKEDUSERS}?limit=${blocked_limit}&page=${blocked_page}`,
    data
  );
};
export const deleteUser = (payload) => {
  return postApi(`${DELETEUSERS}`, payload);
};
export const searchActiveUser = (limit, page, value) => {
  return postApi(`${SEARCH_ACTIVEUSER}?limit=${limit}&page=${page}`, value);
};
export const searchBlockedUser = (limit, page, value) => {
  return postApi(`${SEARCH_BLOCKEDUSER}?limit=${limit}&page=${page}`, value);
};
export const searchAllUsers = (limit, page, value) => {
  return postApi(`${SEARCHALLUSERS}?limit=${limit}&page=${page}`, value);
};
export const searchDashboard = (limit, page, value) => {
  return postApi(`${SEARCH_DASHBORD}?limit=${limit}&page=${page}`, value);
};
export const searchDeletedUsers = (limit, page, value) => {
  return postApi(`${SEARCHDELETEUSER}?limit=${limit}&page=${page}`, value);
};
export const searchReportedUsers = (limit, page, value) => {
  return postApi(`${SEARCHREPORTEDUSER}?limit=${limit}&page=${page}`, value);
};
export const blockUser = (id) => {
  return postApi(`${BLOCKUSER}`, id);
};
export const unblockUser = (id) => {
  return postApi(`${UNBLOCKUSER}`, id);
};
export const editProfile = (value) => {
  return postApi(EDITUSERINFO, value);
};
export const deleteImage = (value) => {
  return postApi(DELETEIMAGE, value);
};
export const dashboardUsers = (limit, page, data) => {
  return postApi(`${DASHBOARD}?limit=${limit}&page=${page}`, data);
};
export const jigarTalaUsers = (limit, page, payload) => {
  return postApi(`${JIGARTALA_USER}?limit=${limit}&page=${page}`, payload);
};
export const searchJigarTalaUsers = (limit, page, value) => {
  return postApi(`${SEARCH_JIGARTALA_USER}?limit=${limit}&page=${page}`, value);
};
export const cancelJigarTalaSubcription = (payload) => {
  return postApi(CANCEL_SUBCRIPTIION, payload);
};
export const getAllImages = (id) => {
  return getApi(`${GET_USER_ALL_IMAGES}?userId=${id}`);
};
