/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  next,
  info,
  trash,
  // draw,
  sort,
  reportaction,
  defautlMale,
  sortAsc,
  sortDesc,
} from "../../../Utils/Images";
import { DeletedUsersWrapper, TableData, TableId } from "../../../GlobalStyle";
import { toast } from "react-toastify";
import {
  getProfile,
  getSignupType,
  toastMessage,
} from "../Functions/Functions";
import {
  blockedUsers,
  searchBlockedUser,
  deleteUser,
  unblockUser,
} from "../../../ApiCrud";

import DeleteBlock from "../../Components/DeleteBlock";
import { SelectMultipleUsersActions } from "../../../Utils/SelectMultipleUsersActions";
import MultipleDeleteModal from "../../Components/MultipleDelete";
import * as moment from "moment";
import styled from "styled-components";

const BlockedUsers = () => {
  const [blocked_Users, setblocked_Users] = useState([]);
  const [getAllBlocked, setgetAllBlocked] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [multipleModal, setMultipleModal] = useState(false);
  const [multipleBlockModal, setMultipleBlockModal] = useState(false);
  const [limit, setLimit] = useState(100);
  const [parameter, setParameter] = useState({});
  const [page, setPage] = useState(1);
  const [openActiveDeactive, setOpenActiveDeactive] = useState(false);
  const [sortParam, setSortParam] = useState({
    sortType: "",
    sortBasis: "",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const Year = new Date().getFullYear();

  // Function For Get Blocked Users Listing
  const getBlockedUsers = async () => {
    setLoading(true);
    let res = await blockedUsers(limit, page, sortParam);
    if (res.statusCode === 200) {
      // console.log(res);
      setblocked_Users(res.user_details.totalUsers);
      setgetAllBlocked(res.user_details?.getActiveUsers);
      setLoading(false);
    } else {
      toast.error(toastMessage(res.message));
      setLoading(false);
    }
  };

  // Function For Handle Search Action On Blocked Users
  const handleSearch = async (e) => {
    e.preventDefault();
    setPage(1);
    setLoading(true);
    let res = await searchBlockedUser(limit, page, {
      search: searchVal,
    });
    if (res.statusCode === 200) {
      setgetAllBlocked(res.user_details.getActiveUsers);
      setLoading(false);
    } else {
      setLoading(false);
      // toast.error(res.message);
    }
  };

  // Function For Search Testing
  const getSearchWord = async (word) => {
    let s = word;
    setSearch(true);
    setLoading(true);
    if (s) {
      let res = await searchBlockedUser(limit, page, {
        search: word,
      });
      if (res.statusCode === 200) {
        setgetAllBlocked(res.user_details.getActiveUsers);
        setLoading(false);
      } else {
        setLoading(false);
        // toast.error(res.message);
      }
    }
    if (!s) {
      setgetAllBlocked([]);
      getBlockedUsers();
    }
  };

  // Function For Navigate to Edit Profile Page
  const handleEdit = (value) => {
    // console.log(value, "value ----------------");
    return navigate("/jigar/editprofile", {
      state: { data: value, path: location.pathname, from: "Blocked Users" },
    });
  };

  // Function For Navigate To Users Details Page
  const handleProfile = (ind) => {
    navigate("/jigar/userdetail", {
      state: { id: ind, path: location.pathname, from: "Blocked Users" },
    });
  };

  // Function For Get Status Of Users
  const getStatus = (data) => {
    if (data.is_blocked === true) {
      return "Blocked";
    }
    if (data.is_deleted === true) {
      return "Deleted";
    }
    return "Active";
  };

  // Function For Get Joined Date Of Users
  const getDate = (date) => {
    let update = new Date(date);
    return moment(update).format("MM/DD/YYYY");
  };

  // Function For Handle Delete Actions
  const handleDelete = async () => {
    setModalLoading(true);
    let id = parameter.id;

    let obj = {
      userIds: [id],
    };
    if (parameter.name === "Delete") {
      let res = await deleteUser(obj);
      if (res.statusCode === 200) {
        setOpenActiveDeactive(false);
        setModalLoading(false);
        toast.success(toastMessage(res?.message));
        let update = [];
        getAllBlocked?.map((val) => {
          if (val.id !== id) {
            update.push(val);
          }
        });
        getAllBlocked(update);
        getBlockedUsers();
        console.log(res, "resssssssssssssssssssssss");
      } else {
        setModalLoading(false);
        toast.error(toastMessage(res?.message));
      }
    } else {
      // let res;
      // let user = { userId: id };
      let res = await unblockUser(obj);
      if (res.statusCode === 200) {
        setModalLoading(false);
        setOpenActiveDeactive(false);
        toast.success(toastMessage(res.message));
        getBlockedUsers();
      } else {
        setModalLoading(false);
        toast.error(toastMessage(res.message));
      }
    }
  };

  const handleMultipleDelete = async (type) => {
    setModalLoading(true);
    let id = parameter.id;
    let arr = [];
    Array.isArray(selectedRowsData) && selectedRowsData?.length > 0
      ? selectedRowsData?.map((val) => {
          arr.push(val?.id);
        })
      : arr.push(id);

    let obj = {
      userIds: arr,
    };
    if (multipleModal) {
      let res = await deleteUser(obj);

      if (res.statusCode === 200) {
        let update = [];
        getAllBlocked?.map((val) => {
          if (val.id !== id) {
            update.push(val);
          }
        });
        getAllBlocked(update);
        setModalLoading(false);
        setMultipleModal(false);
        getBlockedUsers();
        toast.success(toastMessage(res.message));
      } else {
        setModalLoading(false);
        toast.error(toastMessage(res.message));
      }
    } else {
      // let res;
      // let user = { userId: id };
      let res = await unblockUser(obj);
      if (res.statusCode === 200) {
        setModalLoading(false);
        setMultipleBlockModal(false);
        toast.success(toastMessage(res.message));
        getBlockedUsers();
      } else {
        setModalLoading(false);
        toast.error(toastMessage(res.message));
      }
    }
  };

  // Function For Delete Action Modal
  const Delete = (payload) => {
    setOpenActiveDeactive(true);
    let para = {
      name: "Delete",
      body: "this user",
      id: payload,
    };
    setParameter(para);
  };

  // Function For UNBLOCK Action Modal
  const unBlock = (payload) => {
    setOpenActiveDeactive(true);
    let para = {
      name: "Unblock",
      body: "this user",
      id: payload,
    };
    // console.log(para);
    setParameter(para);
  };

  // Function For Change Sort Icons ON Sorting Actions
  const srcSortImage = (Basis) => {
    if (Basis === sortParam.sortBasis) {
      if (sortParam.sortType === "ASC") {
        return sortAsc;
      } else {
        return sortDesc;
      }
    } else {
      return sort;
    }
  };

  // Function For Flip Data According to sort
  const sortingDataFlip = (sortBasis) => {
    setSearchVal("");
    setPage(1);
    if (sortBasis === sortParam.sortBasis) {
      setSortParam({
        sortType: sortParam.sortType === "ASC" ? "DESC" : "ASC",
        sortBasis: sortBasis,
        // timeDuration: duration,
      });
    } else {
      setSortParam({
        sortType: "ASC",
        sortBasis: sortBasis,
        // timeDuration: duration,
      });
    }
  };

  const handleAddRem = (obj, type) => {
    console.log(type, "type");
    if (type) {
      if (selectedRowsData?.length > 0) {
        if (Array.isArray(selectedRowsData)) {
          if (selectedRowsData.find((el) => el?.id === obj?.id)) {
            return selectedRowsData.map((el) => {
              if (el.key === obj.key) {
                setSelectedRowsData(obj);
              }
              setSelectedRowsData(el);
            });
          } else {
            setSelectedRowsData([...selectedRowsData, obj]);
          }
        } else {
          setSelectedRowsData([obj]);
        }
      } else {
        setSelectedRowsData([obj]);
      }
    } else {
      let arr = selectedRowsData.filter((el) => el?.id !== obj?.id);
      setSelectedRowsData(arr);
    }
  };

  console.log(selectedRowsData, "selectedRowsData");
  // Function For Sorting Actions
  const getSortParam = (sortBasis) => {
    setSearchVal("");
    setPage(1);
    if (sortBasis === sortParam.sortBasis) {
      setSortParam({
        sortType: sortParam.sortType === "DESC" ? "ASC" : "DESC",
        sortBasis: sortBasis,
      });
    } else {
      setSortParam({
        sortType: "DESC",
        sortBasis: sortBasis,
      });
    }
  };

  // useEffect Hook
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (searchVal.length === 0) {
      setPage(1);
      setSortParam({ sortBasis: "", sortType: "" });
    }
  }, [searchVal]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (!search && searchVal.length === 0) {
      getBlockedUsers();
    }
    if (setSearchVal.length > 0 && page >= 1) {
      getSearchWord(searchVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, sortParam]);

  return (
    <DeletedUsersWrapper>
      {openActiveDeactive && (
        <DeleteBlock
          open={openActiveDeactive}
          close={() => setOpenActiveDeactive(false)}
          confirmSumbit={handleDelete}
          parameter={parameter}
          loading={modalLoading}
        />
      )}
      {multipleModal && (
        <MultipleDeleteModal
          open={multipleModal}
          close={() => setMultipleModal(false)}
          confirmSumbit={handleMultipleDelete}
          type={"Delete"}
          loading={modalLoading}
        />
      )}
      {multipleBlockModal && (
        <MultipleDeleteModal
          open={multipleBlockModal}
          close={() => setMultipleBlockModal(false)}
          confirmSumbit={handleMultipleDelete}
          type={"UnBlock"}
          loading={modalLoading}
        />
      )}
      <div
        className="d-flex ps-2 pt-2"
        style={{
          // height: "200px",
          position: "sticky",
          top: "0",
          overflow: "none",
          alignSelf: "flex-start",
        }}
      >
        <div className="w-50">
          <h3 className="pt-3 ps-2 mb-0 text-xl-start">Blocked Users</h3>
        </div>
        <div className="w-50">
          <span className="d-flex justify-content-end pe-3 page_routes">
            <p className="pt-4 mb-0 dashboard_routes">
              <Link className="dashboard_route_link" to="/jigar/users">
                Users
              </Link>
            </p>
            <img alt="" className="next_logo" src={next} />
            <p className="pt-4 mb-0 reported_routes">Blocked Users</p>
          </span>
        </div>
      </div>
      <div className="dashboard_users">
        <div className="d-flex filter_row p-4 ps-0">
          <div className="w-50 d-flex">
            <span className="mt-1 ps-3">Show</span>
            <select
              className="reported_entries_option ps-2 ms-2"
              onChange={(e) => {
                setSearch(false);
                setPage(1);
                setLimit(e.target.value);
              }}
            >
              {/* <option value="50">50</option> */}
              <option value="100">100</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
            </select>
            <span className="mt-1 ms-2">entries</span>
          </div>
          <div className="w-50 d-flex justify-content-end">
            <form onSubmit={handleSearch}>
              <label>Search :</label>
              <input
                autoFocus
                value={searchVal}
                disabled={loading}
                onChange={(e) => setSearchVal(e.target.value)}
                className="ms-1 serach_users"
                type="text"
              />
            </form>
          </div>
        </div>
        {selectedRowsData?.length > 0 && (
          <SelectMultipleUsersActions
            handleDelete={handleMultipleDelete}
            setMultipleModal={setMultipleModal}
            setMultipleBlockModal={setMultipleBlockModal}
          />
        )}
        <div>
          {loading ? (
            <div className="LoaderWrapper ">
              <div className="BackgroundLoader"></div>
            </div>
          ) : (
            <div style={{ minHeight: "358px" }}>
              <div style={{ overflowX: "scroll" }} className="p-0">
                <table className="">
                  <thead style={{ top: 0 }} className="TableHeader">
                    {/* <UserHeader sort={(val) => sortedAge(val)} /> */}
                    <tr className="table_row sticky-table-headers__sticky">
                      {/* Members Heading */}
                      <th className="members p-2 pt-4 pb-4">
                        <span className="span1 d-flex justify-content-center">
                          {" "}
                        </span>
                      </th>
                      <th className="members p-2 pt-4 pb-4">
                        <span className="span1 d-flex justify-content-center">
                          {/* #<i class="bi bi-arrow-down-up"></i> */}#
                          {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                        </span>
                      </th>
                      <th className="members pt-4 pb-4">
                        <span className="span1 d-flex justify-content-center">
                          Thumbnail{" "}
                          {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                        </span>
                      </th>
                      <th className="members pt-4 pb-4">
                        <span className="span1 d-flex justify-content-center">
                          Users
                          {/* <img
              alt=""
              className="sort1 mt-auto mb-auto"
              src={sort}
              onClick={() => props.sort("user")}
            /> */}
                        </span>
                      </th>
                      <th className="members pt-4 pb-4">
                        <span className="span1 d-flex justify-content-center">
                          Joined
                          <img
                            alt=""
                            className="sort1 mt-auto mb-auto ps-3"
                            src={srcSortImage("Date")}
                            onClick={() => sortingDataFlip("Date")}
                          />
                        </span>
                      </th>
                      <th className="members pt-4 pb-4">
                        <span className="span1 d-flex justify-content-center">
                          M/F
                          <img
                            alt=""
                            className="sort1 mt-auto mb-auto"
                            src={srcSortImage("Gender")}
                            onClick={() => sortingDataFlip("Gender")}
                          />
                        </span>
                      </th>
                      <th className="members pt-4 pb-4">
                        <span className="span1 d-flex justify-content-center">
                          Location
                          {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                        </span>
                      </th>
                      <th className="members pt-4 pb-4">
                        <span className="span1 d-flex justify-content-center">
                          Device
                          {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                        </span>
                      </th>
                      <th className="members pt-4 pb-4">
                        <span className="span1 d-flex justify-content-center">
                          Status
                          {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                        </span>
                      </th>
                      <th className="members pt-4 pb-4">
                        <span className="span1 d-flex justify-content-center">
                          Age
                          <img
                            alt=""
                            className="sort1 mt-auto mb-auto"
                            src={srcSortImage("Age")}
                            onClick={() => getSortParam("Age")}
                          />
                        </span>
                      </th>
                      <th className="members pt-4 pb-4">
                        <span className="span1 d-flex justify-content-center">
                          Login Type
                          {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                        </span>
                      </th>
                      <th className="members pt-4 pb-4">
                        <span className="span1 d-flex justify-content-center">
                          Action
                          {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <TableBody>
                    {getAllBlocked?.length > 0 ? (
                      getAllBlocked?.map((value, index) => (
                        <tr className="">
                          <TableData>
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleAddRem(value, e.target.checked)
                              }
                            />
                          </TableData>
                          <TableId className="tableData">{index + 1}</TableId>
                          <TableData className="tableData">
                            {value?.Photos.length > 0 ? (
                              <img
                                alt=""
                                className="tableDataimg ms-2"
                                src={getProfile(value?.Photos)}
                              />
                            ) : (
                              <img
                                style={{ objectFit: "cover" }}
                                alt=""
                                className="tableDataimg ms-2"
                                src={defautlMale}
                              />
                            )}
                          </TableData>
                          <TableData
                            className="tableData text-hover-primary"
                            onClick={() => handleEdit(value)}
                          >
                            <span style={{ borderBottom: "1px solid #ccc" }}>
                              {value?.BasicInfo?.name}
                            </span>
                          </TableData>
                          <TableData className="tableData ps-2 pe-2">
                            {getDate(value.createdAt)}
                          </TableData>
                          <TableData className="tableData">
                            {value?.BasicInfo?.gender}
                          </TableData>
                          <TableData className="tableData">
                            {value?.BasicInfo?.current_location === "null"
                              ? "n/a"
                              : value?.BasicInfo?.current_location}
                          </TableData>
                          <TableData className="tableData">
                            {value?.platform}
                          </TableData>
                          <TableData className="tableData">
                            {getStatus(value)}
                          </TableData>
                          <TableData className="tableData">
                            {value?.BasicInfo?.age}
                          </TableData>
                          <TableData className="tableData">
                            {value?.signup_type === 0
                              ? `${
                                  value?.country_code !== null
                                    ? `(${value?.country_code})`
                                    : ""
                                }${value?.mobile_number}`
                              : getSignupType(value?.signup_type)}
                          </TableData>
                          <TableData className="tableData ps-2 pe-3">
                            <div className="d-flex action_btn_group">
                              {/* <button
                              onClick={() => handleEdit(value)}
                              className="action_edit"
                            >
                              <img alt="" src={draw} />
                            </button> */}
                              <button
                                onClick={() => handleProfile(value.id)}
                                className="action_info"
                              >
                                {" "}
                                <img alt="" src={info} />
                              </button>
                              <button
                                onClick={() => Delete(value?.id)}
                                className="action_delete"
                              >
                                {" "}
                                <img alt="" src={trash} />
                              </button>
                              <button
                                onClick={() => unBlock(value?.id)}
                                className="action_report"
                              >
                                {" "}
                                <img alt="" src={reportaction} />
                              </button>
                            </div>
                          </TableData>
                        </tr>
                      ))
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          width: "95%",
                          paddingTop: "10%",
                        }}
                        className="d-flex justify-content-center text-success ps-3 fs-2"
                      >
                        No Data Found
                      </div>
                    )}
                  </TableBody>
                </table>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex pt-5 ps-4">
          <div className="w-50 pt-2">
            <p style={{ fontSize: "14px" }}>
              Showing {page > 1 ? (page - 1) * limit + 1 : 1} to{" "}
              {page > 1
                ? limit * page
                : getAllBlocked?.length >= limit
                ? limit
                : getAllBlocked?.length}{" "}
              of {blocked_Users} entries
            </p>
          </div>
          <div className="w-50">
            <div className="d-flex justify-content-end">
              <div className="pagination me-5">
                <ul>
                  {page > 1 && (
                    <li
                      onClick={() => {
                        if (!loading) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      Previous
                    </li>
                  )}
                  {getAllBlocked?.length <= limit && page > 1 && (
                    <li
                      onClick={() => {
                        if (!loading) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      {getAllBlocked?.length <= limit ? page - 1 : ""}
                    </li>
                  )}
                  <li className="active">{page}</li>
                  {getAllBlocked?.length >= limit && (
                    <li
                      onClick={() => {
                        if (!loading) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      {getAllBlocked?.length >= limit ? page + 1 : ""}
                    </li>
                  )}
                  {getAllBlocked?.length >= limit && (
                    <li
                      onClick={() => {
                        if (!loading) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      {getAllBlocked?.length >= limit ? "Next" : ""}
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-center "
        style={{
          backgroundColor: "#ecf0f5",
        }}
      >
        <span className="d-flex justify-content-center">
          <span style={{ fontWeight: "500", fontSize: "14px" }}>
            {Year.toString()} Jigar.
          </span>
          <p style={{ fontSize: "14px" }} className="ps-2">
            All rights reserved
          </p>
        </span>
      </div>
    </DeletedUsersWrapper>
  );
};
export default BlockedUsers;

const TableBody = styled.tbody`
  width: 100%;
  overflow-x: scroll !important;
`;
