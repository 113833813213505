import { REMEMBER, RESET } from "./actionType";
export const RememberMe = (value) => {
  return {
    type: REMEMBER,
    payload: value,
  };
};
export const Reset = () => {
  return {
    type: RESET,
  };
};
