import React from "react";
import { Outlet } from "react-router-dom";
import SideDiv from "../Components/SideDiv";
import { LandingWrapper } from "../GlobalStyle";
import "../Style/Landing.css";
const Landing = () => {
  return (
    <LandingWrapper>
      <div className="d-flex p-0">
        <div
          className="p-0"
          style={{
            maxWidth: "16%",
            minWidth: "16%",
            minHeight: "100%",
            maxHeight: "100%",
          }}
        >
          <SideDiv />
        </div>
        <div className="dashboard_outlet">
          <Outlet />
        </div>
      </div>
    </LandingWrapper>
  );
};

export default Landing;
