import * as moment from "moment";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { InfoWrapper, InfoLabel, InfoValue, InfoLine } from "../../GlobalStyle";

const SuperLikesModal = ({ show, onhide, data }) => {
  const dateofpurchase = (date) => {
    let update = new Date(date);
    return moment(update).format("MM/DD/YYYY");
  };
  const style = {
    position: "absolute",
    right: "-20px",
    top: "-20px",
    width: "50px",
    height: "50px",
    background: "red",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffffff",
    fontSize: "28px",
    zIndex: "1",
    cursor: "pointer",
  };
  return (
    <div>
      <Modal show={show} onHide={onhide}>
        <span style={style} onClick={onhide}>
          &#10008;
        </span>
        <Modal.Header>
          <Modal.Title className="modalTitle">SuperLikes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InfoWrapper>
            <div>
              <InfoLine>
                {/* <InfoLabel>Status</InfoLabel> */}
                <InfoLabel>SuperLiked Purchased</InfoLabel>
                <InfoLabel>SuperLikes $</InfoLabel>
                <InfoLabel>Purchased Date</InfoLabel>
              </InfoLine>
              <div style={{ overflowY: "scroll", maxHeight: "380px" }}>
              {data.length > 0 ? (
                data.map((value) => (
                  <>
                    <InfoLine>
                      {/* <InfoValue>$ {value?.actual_amount}</InfoValue> */}
                      <InfoValue>
                        {" "}
                        {value?.numberofSuperLikesPurchased}
                      </InfoValue>
                      <InfoValue>{value?.amount}</InfoValue>
                      <InfoValue>
                        {dateofpurchase(value.createdAt)}
                      </InfoValue>
                    </InfoLine>
                    <hr className="m-0 p-0"></hr>
                  </>
                ))
              ) : (
                <span className="text-danger font-weight-bold">No Data</span>
              )}
              </div>
            </div>
          </InfoWrapper>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SuperLikesModal;
