import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Login from "../Auth/Login";
import Landing from "../Page/Landing";
import Dashboard from "../App/Dashboard/Dashboard2";
import Reportedusers from "../App/Module/ReportedUsers/Reported_users";
import ErrorPage from "../Page/ErrorPage";
import DeletedUsers from "../App/Module/DeletedUsers/Deleted_Users";
import BlockedUsers from "../App/Module/BlockedUsers/BlockedUsers";
// import UserProfile from "../App/Module/User/UserProfile";
import EditProfile from "../App/Module/User/EditProfile";
import AddUsers from "../Page/AddUsers";
import { useSelector } from "react-redux";
import User from "../App/Module/User/User";
import UserDetail from "../App/Module/UserDetail";
import ActiveUsers from "../App/Module/ActiveUsers/ActiveUsers";
import UserMedia from "../App/Module/User/UserMedia";
import JigarTalaListing from "../App/Module/EnableJigarTala/JigarTalaEnableListing";
// import Dashboard2 from "../App/Dashboard/Dashboard";

// This function is used for Authentication when user's credentials will be valid.
function PublicRoute({ isAuthenticated }) {
  if (isAuthenticated !== null) {
    return <Navigate to="/jigar/users" replace />;
  }
  return <Outlet />;
}

// This function is used for Authentication when user's credentials will be invalid.
function PrivateRoute({ isAuthenticated }) {
  if (isAuthenticated === null) return <Navigate to="/" />;
  return <Outlet />;
}

function AllRoutes() {
  const credentials = useSelector((state) => state.Auth.token);
  // const credentials = null;
  return (
    <Routes>
      <Route element={<PublicRoute isAuthenticated={credentials} />}>
        <Route path="/" element={<Login />} />
      </Route>

      <Route element={<PrivateRoute isAuthenticated={credentials} />}>
        <Route path="/jigar" element={<Landing />}>
          <Route index element={<Dashboard />} />
          <Route path="users" element={<User />} />
          <Route path="activeusers" element={<ActiveUsers />} />
          <Route path="reported" element={<Reportedusers />} />
          <Route path="deleted" element={<DeletedUsers />} />
          <Route path="blocked" element={<BlockedUsers />} />
          <Route path="jigartala-enabled" element={<JigarTalaListing />} />
          {/* <Route path="userprofile" element={<UserProfile />} /> */}
          <Route path="editprofile" element={<EditProfile />} />
          <Route path="usermedia" element={<UserMedia />} />
          <Route path="adduser" element={<AddUsers />} />
          <Route path="userdetail" element={<UserDetail />} />
          {/* <Route path="dash2" element={<Dashboard2 />} /> */}
        </Route>
        <Route path="/*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
}

export default AllRoutes;
