import React from "react";
import { Button, Modal } from "react-bootstrap";
import styled from "styled-components";
import "../../Style/Users.css";

export default function CancelSubscription({
  open,
  close,
  deleteAction,
  confirmSumbit,
  type,
  loading,
}) {
  const actionConfirm = () => {
    confirmSumbit(deleteAction);
  };
  const style = {
    position: "absolute",
    right: "-17px",
    top: "-12px",
    width: "30px",
    height: "30px",
    background: "red",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffffff",
    fontSize: "24px",
    zIndex: "1",
    cursor: "pointer",
  };
  return (
    <Modal
      show={open}
      onHide={close}
      size="sm"
      // dialogClassName="modal-80w"
      centered
      style={{ background: "rgba(0,0,0,0.6)" }}
      aria-labelledby="example-custom-modal-styling-title"
    >
      <span style={style} onClick={close}>
        &#10008;
      </span>
      <Modal.Body>
        <ModalMessage style={{ fontSize: "16px" }}>
          Are you sure you want to {type}?
        </ModalMessage>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button className="btn btn-sm btn-primary" onClick={actionConfirm}>
            {loading ? <Loader /> : "Cancel Subscription"}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
const ModalMessage = styled.div`
  font-size: 22px;
  text-align: center;
  padding: 10px 0;
`;
const Loader = styled.div`
  width: 20px;
  height: 20px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  border-bottom: transparent;
  animation: spinner-border 0.75s linear infinite;
  position: relative;
  z-index: 99;
  opacity: 1;
`;
