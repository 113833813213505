/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import {
  DashboardWrapper,
  DashboardHeader,
  TableData,
  TableId,
} from "../../../GlobalStyle";
import {
  getProfile,
  getSignupType,
  toastMessage,
} from "../Functions/Functions";
import {
  dollar,
  people,
  // draw,
  info,
  trash,
  reportaction,
  sort,
  defautlMale,
  sortAsc,
  sortDesc,
} from "../../../Utils/Images";
import "../../../Style/Dashboard.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import UserHeader from "../Components/UserHeader";
import {
  listUsers,
  searchAllUsers,
  blockUser,
  deleteUser,
} from "../../../ApiCrud";
import { toast } from "react-toastify";
import "../../../Style/Users.css";
import DeleteBlock from "../../Components/DeleteBlock";
import * as moment from "moment";
import { SelectMultipleUsersActions } from "../../../Utils/SelectMultipleUsersActions";
import MultipleDeleteModal from "../../Components/MultipleDelete";
import { DoubleScrollbar } from "react-double-scrollbar";

const User = () => {
  const [totalUsers, setTotalUsers] = useState([]);
  const navigate = useNavigate();
  const [totalPurchase, setTotalPurchase] = useState();
  const [allusers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(false);
  const [searchVAl, setSearchVal] = useState("");
  const [modalLoading, setModalLoading] = useState(false);
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(1);
  const [parameter, setParameter] = useState({});
  const [duration, setDuration] = useState("");
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [multipleModal, setMultipleModal] = useState(false);
  const [multipleBlockModal, setMultipleBlockModal] = useState(false);
  const [sortParam, setSortParam] = useState({
    sortType: "",
    sortBasis: "",
    timeDuration: duration,
  });
  const location = useLocation();
  const [openActiveDeactive, setOpenActiveDeactive] = useState(false);

  // Function For Get User Listing
  const getAllUsers = async () => {
    setLoading(true);
    let res = await listUsers(limit, page, sortParam);
    if (res.statusCode === 200) {
      setTotalUsers(res.user_details?.totalUsers);
      setTotalPurchase(res.user_details?.totalPurchase);
      setAllUsers(res?.user_details?.allUsers);

      setLoading(false);
    } else {
      toast.error(toastMessage(res.response.data.message));
      setLoading(false);
    }
  };

  console.log(selectedRowsData, "selectedRowsData");

  const handleAddRem = (obj, type) => {
    console.log(type, "type");
    if (type) {
      if (selectedRowsData?.length > 0) {
        if (Array.isArray(selectedRowsData)) {
          if (selectedRowsData.find((el) => el?.id === obj?.id)) {
            return selectedRowsData.map((el) => {
              if (el.key === obj.key) {
                setSelectedRowsData(obj);
              }
              setSelectedRowsData(el);
            });
          } else {
            setSelectedRowsData([...selectedRowsData, obj]);
          }
        } else {
          setSelectedRowsData([obj]);
        }
      } else {
        setSelectedRowsData([obj]);
      }
    } else {
      let arr = selectedRowsData.filter((el) => el?.id !== obj?.id);
      setSelectedRowsData(arr);
    }
  };

  // Function For Handle Search Actions
  const handleSearch = async (e) => {
    e.preventDefault();
    setPage(1);
    setLoading(true);
    let res = await searchAllUsers(limit, page, {
      search: searchVAl,
    });
    if (res.statusCode === 200) {
      setAllUsers(res.user_details.allUsers);
      setLoading(false);
      setSearch(false);
    } else {
      setAllUsers([]);
    }
  };

  // Function For Page Two And Next Page Search
  const getSearchWord = async (word) => {
    setSearch(true);
    setLimit(100);
    setLoading(true);

    let s = word;

    if (s) {
      let res = await searchAllUsers(limit, page, {
        search: word,
      });
      if (res.statusCode === 200) {
        // console.log(res, "searchData", word, "word");
        setAllUsers(res.user_details.allUsers);
        setLoading(false);
        setSearch(false);
      } else {
        setAllUsers([]);
      }
    }
    if (!s) {
      setAllUsers([]);

      getAllUsers();
    }
  };

  // Function For Sorting Actions
  const getSortParam = (sortBasis) => {
    setSearchVal("");
    setPage(1);
    if (sortBasis === sortParam.sortBasis) {
      setSortParam({
        sortType: sortParam.sortType === "DESC" ? "ASC" : "DESC",
        sortBasis: sortBasis,
        timeDuration: duration,
      });
    } else {
      setSortParam({
        sortType: "DESC",
        sortBasis: sortBasis,
        timeDuration: duration,
      });
    }
  };

  // Function For Navigate To Edit Profile Page
  const handleEdit = (value) => {
    return navigate("/jigar/editprofile", {
      state: { data: value, path: location.pathname, from: "Users" },
    });
  };

  // Function For Navigate To User Detail Page
  const handleProfile = (ind) => {
    navigate("/jigar/userdetail", {
      state: { id: ind, path: location.pathname, from: "Users" },
    });
  };

  // Function For Get Joined date OF Users
  const getDate = (date) => {
    let update = new Date(date);
    return moment(update).format("MM/DD/YYYY");
  };

  // Function For Handle Delete And Block Actions
  const handleDelete = async () => {
    setModalLoading(true);
    let id = parameter.id;

    let obj = {
      userIds: [id],
    };
    if (parameter.name === "Delete") {
      let res = await deleteUser(obj);

      if (res.statusCode === 200) {
        let update = [];
        allusers?.map((val) => {
          if (val.id !== id) {
            update.push(val);
          }
        });
        setAllUsers(update);
        setModalLoading(false);
        setOpenActiveDeactive(false);
        getAllUsers();
        toast.success(toastMessage(res.message));
      } else {
        setModalLoading(false);
        toast.error(toastMessage(res.message));
      }
    } else {
      // let res;
      // let user = { userId: id };
      let res = await blockUser(obj);
      if (res.statusCode === 200) {
        setModalLoading(false);
        setOpenActiveDeactive(false);
        toast.success(toastMessage(res.message));
        getAllUsers();
      } else {
        setModalLoading(false);
        toast.error(toastMessage(res.message));
      }
    }
  };

  const handleMultipleDelete = async (type) => {
    setModalLoading(true);
    let id = parameter.id;
    let arr = [];
    Array.isArray(selectedRowsData) && selectedRowsData?.length > 0
      ? selectedRowsData?.map((val) => {
          arr.push(val?.id);
        })
      : arr.push(id);

    let obj = {
      userIds: arr,
    };
    if (multipleModal) {
      let res = await deleteUser(obj);

      if (res.statusCode === 200) {
        let update = [];
        allusers?.map((val) => {
          if (val.id !== id) {
            update.push(val);
          }
        });
        setMultipleModal(false);
        setAllUsers(update);
        setModalLoading(false);
        setOpenActiveDeactive(false);
        setSelectedRowsData([]);
        getAllUsers();
        toast.success(toastMessage(res.message));
      } else {
        setModalLoading(false);
        toast.error(toastMessage(res.message));
      }
    } else {
      // let res;
      // let user = { userId: id };
      let res = await blockUser(obj);
      if (res.statusCode === 200) {
        setModalLoading(false);
        setMultipleBlockModal(false);
        setOpenActiveDeactive(false);
        setSelectedRowsData([]);
        toast.success(toastMessage(res.message));
        getAllUsers();
      } else {
        setModalLoading(false);
        toast.error(toastMessage(res.message));
      }
    }
  };

  const getImageStyling = (value) => {
    if (value?.subscription_status == "active" && !value?.enableByAdmin) {
      if (value?.BasicInfo?.incognito_status) {
        return { border: "3px solid #D6B357", opacity: "0.6" };
      } else {
        return { border: "3px solid #D6B357" };
      }
    } else {
      if (value?.BasicInfo?.incognito_status) {
        return { opacity: "0.6" };
      } else {
        return {};
      }
    }
  };

  // Function For Delete Action Modal
  const Delete = (payload) => {
    setOpenActiveDeactive(true);
    let para = {
      name: "Delete",
      body: "this user",
      id: payload,
    };
    setParameter(para);
  };

  // Function For BLock Action Modal
  const Block = (payload) => {
    setOpenActiveDeactive(true);
    let para = {
      name: "Block",
      body: "this user",
      id: payload,
    };
    setParameter(para);
  };

  const Year = new Date().getFullYear();

  // Function For Change Sort Icons On Sorting Actions
  const srcSortImage = (Basis) => {
    if (Basis === sortParam.sortBasis) {
      if (sortParam.sortType === "ASC") {
        return sortAsc;
      } else {
        return sortDesc;
      }
    } else {
      return sort;
    }
  };

  const loadDefault = async () => {
    setLoading(true);
    setPage(1);
    setSortParam({
      sortBasis: "",
      sortType: "",
      duration: "",
    });
  };

  // Function For Data Flip According to sorting
  const sortingDataFlip = (sortBasis) => {
    setSearchVal("");
    setPage(1);
    if (sortBasis === sortParam.sortBasis) {
      setSortParam({
        sortType: sortParam.sortType === "ASC" ? "DESC" : "ASC",
        sortBasis: sortBasis,
        timeDuration: duration,
      });
    } else {
      setSortParam({
        sortType: "ASC",
        sortBasis: sortBasis,
        timeDuration: duration,
      });
    }
  };

  // useEffect Hook
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (searchVAl.length === 0) {
      setPage(1);
      setSortParam({ sortBasis: "", sortType: "", duration: "" });
    }
  }, [searchVAl]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (!search && searchVAl.length === 0) {
      getAllUsers();
    }
    if (searchVAl.length > 0 && page > 1) {
      getSearchWord(searchVAl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, sortParam]);

  return (
    <DashboardWrapper>
      {openActiveDeactive && (
        <DeleteBlock
          open={openActiveDeactive}
          close={() => setOpenActiveDeactive(false)}
          confirmSumbit={handleDelete}
          parameter={parameter}
          loading={modalLoading}
        />
      )}
      {multipleModal && (
        <MultipleDeleteModal
          open={multipleModal}
          close={() => setMultipleModal(false)}
          confirmSumbit={handleMultipleDelete}
          type={"Delete"}
          loading={modalLoading}
        />
      )}
      {multipleBlockModal && (
        <MultipleDeleteModal
          open={multipleBlockModal}
          close={() => setMultipleBlockModal(false)}
          confirmSumbit={handleMultipleDelete}
          type={"Block"}
          loading={modalLoading}
        />
      )}
      {/* <DashboardHeader className="d-flex mt-4">
        <div className="col-md-2">
          <h3 style={{ fontFamily: "Poppins" }} className="mt-3 ps-4">
            All Users
          </h3>
        </div>
        <div className="col-md-3 justify-content-end ">
          <div className="d-flex justify-content-center">
            <div className="col-md-4 d-flex justify-content-center p-3 user_group_logo">
              <img alt="" src={people} />
            </div>
            <div className="col-md-6 total_users_bg">
              <p className="mt-1 mb-0">TOTAL USERS</p>
              {loading ? (
                <div className="wave">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                </div>
              ) : (
                <h5 className="m-0 text-start text-center">{totalUsers}</h5>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-3 justify-content-end">
          <div className="d-flex justify-content-end">
            <div className="col-md-4 d-flex justify-content-center p-3 dollar_sign_logo">
              <img alt="" src={dollar} />
            </div>
            <div className="col-md-6 total_users_bg">
              <p className="mt-1 mb-0">TOTAL PURCHASE</p>
              {loading ? (
                <div className="wave">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                </div>
              ) : (
                <h5 className="m-0 text-start text-center">
                  ${Math.floor(totalPurchase)}
                </h5>
              )}
            </div>
          </div>
        </div>
        <div className="w-25">
          <div className="d-flex justify-content-end mt-3">
            <Link
              className=""
              to="/jigar/activeusers"
              state={{ from: "Users" }}
            >
              <button
                style={{
                  backgroundColor: "#00c0ef",
                  color: "white",
                  cursor: "pointer",
                }}
                className="btn"
              >
                Active Users
              </button>
            </Link>
          </div>
        </div>
      </DashboardHeader> */}
      <DashboardHeader className="d-flex mt-4 mb-5">
        <div className="w-15">
          <h3
            style={{ fontFamily: "Poppins", marginLeft: "0.6rem" }}
            className="mt-3 ps-4 pe-4"
          >
            All Users
          </h3>
        </div>
        <div className="w-25 ms-5">
          <div style={{ cursor: "pointer" }} className="d-flex">
            <div className="col-md-4 d-flex justify-content-center p-3 user_group_logo">
              <img alt="" src={people} />
            </div>
            <div className="col-md-6 total_users_bg">
              <p className="mt-1 mb-0 fs-6">TOTAL USERS</p>
              {loading ? (
                <div className="wave">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                </div>
              ) : (
                <h4 className="m-0 text-start text-center">{totalUsers}</h4>
              )}
            </div>
          </div>
        </div>
        <div className="w-25">
          <div
            style={{ cursor: "pointer" }}
            className="d-flex justify-content-end"
          >
            <div className="col-md-4 d-flex justify-content-center p-3 dollar_sign_logo">
              <img alt="" src={dollar} />
              {/* <ion-icon name="heart-outline"></ion-icon> */}

              {/* <i class="icon ionicons ion-social-usd"></i> */}
            </div>
            <div className="col-md-6 total_users_bg">
              <p className="mt-1 mb-0 fs-6">TOTAL PURCHASE</p>
              {loading ? (
                <div className="wave">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                </div>
              ) : (
                <h4 className="m-0 text-start text-center">
                  ${Math.floor(totalPurchase)}
                </h4>
              )}
            </div>
          </div>
        </div>
        <div className="w-25">
          <div className="d-flex justify-content-end mt-3">
            <Link
              className=""
              to="../activeusers"
              path="activeusers"
              state={{ from: "All User" }}
            >
              <button
                style={{
                  backgroundColor: "#00c0ef",
                  color: "white",
                  cursor: "pointer",
                }}
                className="btn"
              >
                Active Users
              </button>
            </Link>
          </div>
        </div>
      </DashboardHeader>
      {/* <DoubleScrollbar> */}
      <div className="dashboard_users" style={{ overflowX: "scroll" }}>
        <div className="d-flex mt-2 filter_row p-4 pb-2 ps-0">
          <div className="w-25 d-flex">
            <span
              style={{ fontSize: "15px", fontFamily: "Poppins" }}
              className="mt-1 ps-3"
            >
              Show
            </span>
            <select
              className="entries_option ps-2 ms-2"
              onChange={(e) => {
                setPage(1);
                setLimit(e.target.value);
                setSearchVal("");
              }}
            >
              {/* <option value="50">50</option> */}
              <option value="100">100</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
            </select>
            <span
              style={{ fontSize: "15px", fontFamily: "Poppins" }}
              className="mt-1 ms-2"
            >
              entries
            </span>
          </div>
          <div className="w-25 d-flex justify-content-end">
            <form onSubmit={handleSearch}>
              <label style={{ fontSize: "15px", fontFamily: "Poppins" }}>
                Search :
              </label>
              <input
                autoFocus
                value={searchVAl}
                disabled={loading}
                onChange={(e) => setSearchVal(e.target.value)}
                className="ms-1 serach_users"
                type="text"
              />
            </form>
          </div>
          <div
            style={{ width: "15%" }}
            className="d-flex justify-content-end me-3"
          >
            {sortParam.sortBasis !== "" ? (
              <button
                style={{ color: "#3c8dbc" }}
                className="btn btn-sm btn-info fs text-white"
                onClick={() => loadDefault()}
              >
                Reset filters
              </button>
            ) : (
              ""
            )}
          </div>
          <div
            style={{ width: "35%" }}
            className="d-flex justify-content-center"
          >
            <select
              style={{ fontSize: "15px", fontFamily: "Poppins" }}
              className="filter_option ps-3 w-75"
              onChange={(e) => {
                let data = {
                  sortType: sortParam.sortType,
                  sortBasis: sortParam.sortBasis,
                  timeDuration: e.target.value,
                };
                setDuration(e.target.value);
                setPage(1);
                setSortParam(data);
                setSearchVal("");
              }}
            >
              {/* <option hidden selected value=""></option> */}
              <option value="">Total</option>
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="lastweek">Last week</option>
              <option value="lastmonth">Last month</option>
              <option value="lastyear">Last year</option>
            </select>
          </div>
        </div>
        {selectedRowsData?.length > 0 && (
          <SelectMultipleUsersActions
            handleDelete={handleMultipleDelete}
            setMultipleModal={setMultipleModal}
            setMultipleBlockModal={setMultipleBlockModal}
          />
        )}

        <div>
          {loading ? (
            <div className="LoaderWrapper">
              <div className="BackgroundLoader"></div>
            </div>
          ) : (
            <div className="dashboard_table  flipped p-0">
              <table className="">
                {/* <div className="fliper"> */}
                <thead className="TableHeader">
                  <tr className="table_row sticky-table-headers__sticky">
                    <th className="members p-2 pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center"></span>
                    </th>
                    <th className="members p-2 pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        #
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Thumbnail{" "}
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Users
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span
                        style={{ whiteSpace: "nowrap" }}
                        className="span1 d-flex justify-content-center"
                      >
                        Last Active
                        <img
                          alt=""
                          className="sort1 mt-auto mb-auto ps-3"
                          src={srcSortImage("lastActive")}
                          onClick={() => sortingDataFlip("lastActive")}
                        />
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Joined
                        <img
                          alt=""
                          className="sort1 mt-auto mb-auto ps-3"
                          src={srcSortImage("Date")}
                          onClick={() => sortingDataFlip("Date")}
                        />
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        M/F
                        <img
                          alt=""
                          className="sort1 mt-auto mb-auto"
                          src={srcSortImage("Gender")}
                          onClick={() => sortingDataFlip("Gender")}
                        />
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Location
                        {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Device
                        <img
                          alt=""
                          className="sort1 mt-auto mb-auto"
                          src={srcSortImage("device")}
                          onClick={() => getSortParam("device")}
                        />
                        {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Status
                        <img
                          alt=""
                          className="sort1 mt-auto mb-auto"
                          src={srcSortImage("status")}
                          onClick={() => getSortParam("status")}
                        />
                        {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Age
                        <img
                          alt=""
                          className="sort1 mt-auto mb-auto"
                          src={srcSortImage("Age")}
                          onClick={() => getSortParam("Age")}
                        />
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Login Type
                        <img
                          alt=""
                          className="sort1 mt-auto mb-auto"
                          src={srcSortImage("loginType")}
                          onClick={() => getSortParam("loginType")}
                        />
                        {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Action
                        {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                      </span>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {allusers?.length > 0 ? (
                    allusers?.map((value, index) => (
                      <tr className="">
                        <TableData>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              handleAddRem(value, e.target.checked)
                            }
                          />
                        </TableData>
                        <TableId className="tableData">
                          {page > 1
                            ? limit * (page - 1) + (1 + index)
                            : index + 1}
                        </TableId>
                        <TableData className="tableData">
                          {value?.Photos.length > 0 ? (
                            <img
                              style={getImageStyling(value)}
                              alt=""
                              className="tableDataimg ms-2"
                              src={getProfile(value?.Photos)}
                            />
                          ) : (
                            <img
                              style={{ objectFit: "cover" }}
                              alt=""
                              className="tableDataimg ms-2"
                              src={defautlMale}
                            />
                          )}
                        </TableData>
                        <TableData
                          className="tableData text-hover-primary"
                          onClick={() => handleEdit(value)}
                        >
                          <span style={{ borderBottom: "1px solid #ccc" }}>
                            {value?.BasicInfo?.name
                              ? value?.BasicInfo?.name
                              : "---"}
                          </span>
                        </TableData>
                        <TableData className="tableData ps-2 pe-2">
                          {getDate(value?.last_active)}
                          <br />
                          {value?.last_active &&
                            moment(value?.last_active).format("HH:mm")}
                        </TableData>
                        <TableData className="tableData ps-2 pe-2">
                          {getDate(value?.createdAt)}
                        </TableData>
                        <TableData className="tableData">
                          {value?.BasicInfo?.gender
                            ? value?.BasicInfo?.gender
                            : "---"}
                        </TableData>
                        <TableData className="tableData">
                          {value?.BasicInfo
                            ? value?.BasicInfo.current_location
                              ? value?.BasicInfo?.current_location == "null"
                                ? "---"
                                : value?.BasicInfo?.current_location
                              : "---"
                            : "---"}
                        </TableData>
                        <TableData className="tableData">
                          {value?.platform ? value?.platform : "---"}
                        </TableData>
                        <TableData className="tableData">
                          {value?.is_profile_completed ? "Active" : "Inactive"}
                        </TableData>
                        <TableData className="tableData">
                          {value?.BasicInfo?.age
                            ? value?.BasicInfo?.age
                            : "---"}
                        </TableData>
                        <TableData className="tableData">
                          {value?.signup_type === 0
                            ? `${
                                value?.country_code !== null
                                  ? `(${value?.country_code})`
                                  : "---"
                              }${value?.mobile_number}`
                            : getSignupType(value?.signup_type)}
                        </TableData>
                        <TableData className="tableData ps-2 pe-3">
                          <div className="d-flex action_btn_group">
                            {/* <button
                                onClick={() => handleEdit(value)}
                                className="action_edit"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit"
                              >
                                <img alt="" src={draw} />
                              </button> */}
                            <button
                              onClick={() => handleProfile(value.id)}
                              className="action_info"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Info"
                            >
                              {" "}
                              <img alt="" src={info} />
                            </button>
                            <button
                              onClick={() => Delete(value?.id)}
                              className="action_delete"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Delete"
                            >
                              {" "}
                              <img alt="" src={trash} />
                            </button>
                            <button
                              onClick={() => Block(value?.id)}
                              className="action_report"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Block"
                            >
                              {" "}
                              <img alt="" src={reportaction} />
                            </button>
                          </div>
                        </TableData>
                      </tr>
                    ))
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        width: "95%",
                        paddingTop: "10%",
                      }}
                      className="d-flex justify-content-center text-success ps-3 fs-2"
                    >
                      No Data Found
                    </div>
                  )}
                </tbody>
                {/* </div>{" "} */}
              </table>
            </div>
          )}
        </div>
      </div>
      {/* </DoubleScrollbar> */}
      <div
        className="d-flex w-100 pt-1 ps-4"
        style={{
          display: "none",
          // position: "fixed",
          // bottom: "40px"
          backgroundColor: "#ecf0f5",
        }}
      >
        <div className="w-50 p-0" style={{ height: "max-content" }}>
          <p className="m-0" style={{ fontSize: "14px" }}>
            Showing {page > 1 ? (page - 1) * limit + 1 : 1} to{" "}
            {page > 1
              ? limit * page
              : allusers?.length >= limit
              ? limit
              : allusers?.length}{" "}
            of {totalUsers} entries
          </p>
        </div>
        <div className="w-50" style={{ height: "max-content" }}>
          <div className="d-flex justify-content-end">
            <div className="pagination">
              <ul>
                {page > 1 && (
                  <li
                    onClick={() => {
                      if (!loading) {
                        setPage(page - 1);
                      }
                    }}
                  >
                    Previous
                  </li>
                )}
                {allusers?.length <= limit && page > 1 && (
                  <li
                    onClick={() => {
                      if (!loading) {
                        setPage(page - 1);
                      }
                    }}
                  >
                    {allusers?.length <= limit ? page - 1 : ""}
                  </li>
                )}
                {/* <li>{page > 0 ? page - 1 : null}</li> */}
                <li className="active">{page}</li>
                {allusers?.length >= limit && (
                  <li
                    onClick={() => {
                      if (!loading) {
                        setPage(page + 1);
                      }
                    }}
                  >
                    {allusers?.length >= limit ? page + 1 : ""}
                  </li>
                )}

                {allusers?.length >= limit && (
                  <li
                    onClick={() => {
                      if (!loading) {
                        setPage(page + 1);
                      }
                    }}
                  >
                    {allusers?.length >= limit ? "Next" : ""}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div
        className="d-flex justify-content-center "
        style={{
          backgroundColor: "#ecf0f5",
        }}
      >
        <span className="d-flex justify-content-center">
          <span style={{ fontWeight: "500", fontSize: "14px" }}>
            {Year.toString()} Jigar.
          </span>
          <p style={{ fontSize: "14px" }} className="ps-2">
            All rights reserved
          </p>
        </span>
      </div>
    </DashboardWrapper>
  );
};

export default User;
