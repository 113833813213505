/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BackIcon, next } from "../../../Utils/Images";
import "../../../Style/EditProfile.css";
import "../../../Style/UserProfile.css";
import { Formik, Form, Field } from "formik";
import { editProfile } from "../../../ApiCrud";
import { toast } from "react-toastify";
import { toastMessage } from "../Functions/Functions";
import styled from "styled-components";
import moment from "moment";
// import { DateSchema } from "yup";
// import { number } from "yup/lib/locale";

const EditProfile = () => {
  const location = useLocation();
  const [clicked, setClicked] = useState(false);
  const data = location.state.data;
  const path = location.state.path;
  // console.log(data, "Edit Data---------");
  const navigate = useNavigate();
  const Year = new Date().getFullYear();

  console.log(data, "data");

  const getTimeStampDate = () => {
    let date = new Date();
    return moment(date).format("YYYY-MM-DD");
  };

  // const mobileNumber = `${
  //   data?.country_code == null ? data?.country_code : ""(data.mobile_number)
  // }`;

  // Code For Get Country Code + Cell Number Of User
  let cell;
  if (data) {
    if (data.country_code === null) {
      cell = 0;
    } else if (data.country_code) {
      cell = data.country_code + " " + data.mobile_number;
    }
  }

  // Code For Get Email Of User
  let email;
  if (data) {
    if (data.email === null) {
      email = 0;
    } else if (data.email) {
      email = data?.email;
    }
  }

  let device = data?.platform;

  // IntialValues Of Form
  const intialValues = {
    bio: data?.BasicInfo?.bio,
    location:
      data?.BasicInfo?.current_location === "null"
        ? ""
        : data?.BasicInfo?.current_location,
    country: data?.BasicInfo?.origin_location,
    age: data?.BasicInfo?.age,
    gender: data?.BasicInfo?.gender,
    cellnumber: cell,
    email: email,
    superlikes: data?.SuperLikesCounts?.[0]?.super_like_counts,
    jigartala: data?.subscription_status !== "active" ? false : true,
    boosterStatus:
      data?.jigar_on_fire_status === true
        ? data?.jigarOnFireTransactions
          ? data?.jigarOnFireTransactions[0]?.purchase_type.toString()
          : "0"
        : "0",
    platform: device,
  };

  // Post Data(req, "Object") From Edit Profile
  const onSubmit = async (values) => {
    setClicked(true);
    let req = {
      userId: data.BasicInfo.userId,
      bio: values.bio,
      current_location: values.location,
      origin_location: values.country,
      age: values.age,
      gender: values.gender,
      mobile_number: values.cell,
      email: values.email,
      jigartalaStatus: values.jigartala,
      addSuperLike: values.superlikes,

      boosterStatus: values.boosterStatus,
      platform: values.platform,
    };
    if (data?.subscription_status !== "active") {
      if (values?.jigartala) {
        req = {
          ...req,
          enableByAdminAt: getTimeStampDate(),
        };
      }
      // req.enableByAdminAt = getTimeStampDate();
    }

    // Code For Navigate After Update
    let res = await editProfile(req);
    if (res.statusCode === 200) {
      navigate(path);
      toast.success(toastMessage(res.message));
    } else {
      // console.log(res, "resssss data");
      toast.error(toastMessage(res?.response?.data?.message));
      setClicked(false);
    }
  };

  useEffect(() => {
    getTimeStampDate();
  }, []);

  // Function For Navigate User Media Page
  const handleMedia = () => {
    navigate("/jigar/usermedia", { state: { data: data.Photos } });
  };

  return (
    <div className="p-3 pb-2">
      <div className="row">
        <div className="col-md-7 pt-3 ps-0">
          <div className="d-flex">
            <BackIconContainer className="pt-2" onClick={() => navigate(-1)}>
              <img src={BackIcon} />
            </BackIconContainer>
            <h3 className="ps-0 p-3 mb-0 pb-0">Edit Profile</h3>
          </div>
        </div>
        <div className="col-md-5">
          <span className="d-flex justify-content-end pt-3 pe-3 page_routes">
            <p className="pt-4 mb-0 dashboard_routes">
              <Link className="dashboard_route_link" to={location.state.path}>
                {location.state.from}
              </Link>
            </p>
            <img alt="" className="next_logo mt-2" src={next} />
            <p className="pt-4 mb-0 reported_routes">Edit Profile</p>
          </span>
        </div>
      </div>
      <div className="row p-3 userProfile_row">
        <div className="col-md-12 userProfile p-3 pt-1 pe-0 ps-0">
          <hr />
          <div className="p-3">
            <Formik initialValues={intialValues} onSubmit={onSubmit}>
              <Form>
                <div className="form-group mb-2">
                  <label>Bio</label>
                  <br />
                  <Field
                    name="bio"
                    as="textarea"
                    autoFocus
                    className="bio_textarea"
                  />
                </div>
                <div className="form-group pt-1 mb-2">
                  <label>Location(city, state)</label>
                  <br />
                  <Field name="location" className="editinputs" type="text" />
                </div>
                <div className="form-group pt-1 mb-2">
                  <label>Country</label>
                  <br />
                  <Field name="country" className="editinputs" type="text" />
                </div>
                <div className="form-group pt-1 mb-2">
                  <label>Age</label>
                  <br />
                  <Field name="age" className="editinputs" type="text" />
                </div>
                <div className="form-group pt-1 mb-2">
                  <label>Gender</label>
                  <Field name="gender" as="select" className="editinputs">
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Field>
                </div>
                <div className="form-group pt-1 mb-2">
                  {data?.signup_type === 3 ? (
                    <>
                      <label>Email</label>
                      <br />
                      <Field name="email" className="editinputs" type="text" />
                    </>
                  ) : data?.signup_type === 1 ? (
                    <>
                      <label>Login Type</label>
                      <br />
                      <Field
                        style={{ backgroundColor: "white" }}
                        value="Facebook"
                        className="editinputs"
                        type="text"
                        disabled
                      />
                    </>
                  ) : data?.signup_type === 2 ? (
                    <>
                      <label>Login Type</label>
                      <br />
                      <Field
                        style={{ backgroundColor: "white" }}
                        value="Apple"
                        className="editinputs"
                        type="text"
                        disabled
                      />
                    </>
                  ) : data?.signup_type === 0 ? (
                    <>
                      <label>Cell number</label>
                      <br />
                      <Field
                        name="cellnumber"
                        className="editinputs"
                        type="text"
                      />
                    </>
                  ) : null}
                </div>
                <div className="form-group pt-1 mb-2">
                  <label>Add Superlikes Or Remove</label>
                  <br />
                  <Field
                    name="superlikes"
                    className="editinputs"
                    type="number"
                  />
                </div>
                <div className="row pt-2">
                  <div className="form-group pt-2 col-md-4">
                    {data?.subscription_status !== "active" ? (
                      <>
                        <label>Enable JigarTala</label>
                        <Field
                          name="jigartala"
                          className="ms-2"
                          type="checkbox"
                        />
                      </>
                    ) : data?.subscription_status === "active" &&
                      data?.enableByAdmin === true ? (
                      <>
                        <label>Enable JigarTala</label>
                        <Field
                          name="jigartala"
                          className="ms-2"
                          type="checkbox"
                        />
                      </>
                    ) : data?.subscription_status === "active" &&
                      data?.enableByAdmin === false ? (
                      <>
                        <label>Enable JigarTala</label>
                        <Field
                          disabled
                          name="jigartala"
                          className="ms-2"
                          type="checkbox"
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* <>
                    <label>Enable JigarTala</label>
                    <Field name="jigartala" className="ms-2" type="checkbox" />
                  </> */}
                  <div className="form-group pt-2 col-md-4">
                    <label>JigarOnFire Local</label>
                    <Field
                      name="boosterStatus"
                      className="ms-2"
                      value="1"
                      type="radio"
                      disabled={intialValues?.boosterStatus === "2"}
                    />
                  </div>
                  <div className="form-group pt-2 col-md-4">
                    <label>JigarOnFire Worldwide</label>
                    <Field
                      name="boosterStatus"
                      value="2"
                      className="ms-2"
                      type="radio"
                      disabled={intialValues?.boosterStatus === "1"}
                    />
                  </div>
                </div>
                <hr />
                <div className="row pt-2">
                  <div className="col-md-1">
                    <button disabled={clicked} className="btn btn-primary">
                      Update
                    </button>
                  </div>
                  <div className="col-md-2 d-flex justify-content-center">
                    <button onClick={handleMedia} className="btn btn-primary">
                      Media
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-center ps-3 pt-1">
        <span className="d-flex justify-content-center">
          <h6 className="pt-4">{Year.toString()} Jigar.</h6>
          <p className="ps-2 footer_about">All rights reserved</p>
        </span>
      </div>
    </div>
  );
};

export default EditProfile;

const BackIconContainer = styled.span`
  img {
    width: 30px;
    height: 35px;
    cursor: pointer;
    font-size: 25px;
    padding: 0 6px 0 0;
    padding-top: 16px;
    transition: font-size;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
  }
  img:hover {
    color: red;
    font-size: 27px;
    overflow: hidden;
  }
`;
