import React from "react";
import { AppLogo, FormWrapper, LoginWrapper } from "../GlobalStyle";
import "../Style/Login.css";
import { jigar_logo } from "../Utils/Images";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { login } from "../ApiCrud";
import { toast } from "react-toastify";

import { Login as loginAction } from "../Store/Auth/action";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useState } from "react";
import { RememberMe, Reset } from "../Store/RememberMe/action";
const Login = () => {
  const [loading, setLoading] = useState(false);
  const data = useSelector((state) => state.UseRemember);
  const dispatch = useDispatch();
  const initialValues = {
    email: data.email,
    password: data.password,
    checkbox: data.email !== "" && data.password !== "" ? true : false,
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .trim("Space is not allowed")
      .email("Invalid email format")
      .required("Email is required !"),
    password: Yup.string()
      .required("Password is required !")
      .min(8, "Password must be 8 characters long")
      .matches(/[0-9]/, "Password requires a number")
      .matches(/[a-z]/, "Password requires a lowercase letter"),
  });
  // console.log(Yup);

  const handleRemember = (values, req) => {
    if (values.checkbox) {
      dispatch(RememberMe(req));
    } else {
      dispatch(Reset());
    }
  };

  const onSubmit = async (values) => {
    let req = {
      email: values.email,
      password: values.password,
    };
    setLoading(true);
    let res = await login(req.email, req.password);
    // console.log(res, "res--------------");

    if (res.statusCode === 200) {
      console.log("login");
      toast.success("Success");
      dispatch(loginAction(res.user_details));
      handleRemember(values, req);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(res?.response?.data?.message);
    }
  };
  return (
    <LoginWrapper>
      <div className="contnet-wrap">
        <AppLogo>
          <img alt="" src={jigar_logo} />
        </AppLogo>
        <FormWrapper>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            <Form type="submit">
              <div className="form-group mx-2 my-4">
                <label>Email</label>
                <Field type="text" className="form-control" name="email" placeholder='Enter Email' />
                <div className="text-danger">
                  <ErrorMessage name="email" />
                </div>
              </div>
              <div className="form-group mx-2 my-4">
                <label>Password</label>
                <Field
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder='Enter Password'
                />
                <div className="text-danger">
                  <ErrorMessage name="password" />
                </div>
              </div>
              <div className="form-group form-check mx-2">
                <Field
                  type="checkbox"
                  className="form-check-input"
                  name="checkbox"
                  
                />
                <label className="form-check-label">Remember me</label>
              </div>
              <div className="w-100 d-flex justify-content-end pb-3">
                {loading ? (
                  <div style={{ paddingRight: "40px" }}>
                    <Loader />
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="btn formSubmitbtn text-white me-3 "
                  >
                    LOG IN
                  </button>
                )}
              </div>
            </Form>
          </Formik>
        </FormWrapper>
      </div>
    </LoginWrapper>
  );
};

export default Login;
const Loader = styled.div`
  width: 30px;
  height: 30px;
  border: 1px solid black;
  border-radius: 50%;
  border-bottom: transparent;
  animation: spinner-border 0.75s linear infinite;
  position: relative;
  z-index: 99;
  opacity: 1;
`;
