import { LOGIN, LOGOUT } from "./actionType";
export const Login = (value) => {
  return {
    type: LOGIN,
    payload: value,
  };
};

export const Logout = () => {
  return {
    type: LOGOUT,
  };
};
