import { REMEMBER, RESET } from "./actionType";

const initState = {
  email: "",
  password: "",
};
const Rem = (state = initState, action) => {
  switch (action.type) {
    case REMEMBER:
      return (state = action.payload);
    case RESET:
      return (state = initState);
    default:
      return state;
  }
};
export default Rem;
