/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BackIcon } from "../../../Utils/Images";
import "../../../Style/UserMedia.css";
import { CardWrapper } from "../../../GlobalStyle";
import { toast } from "react-toastify";
import { deleteImage, getAllImages } from "../../../ApiCrud";
import DeleteBlock from "../../Components/DeleteBlock";
import { toastMessage } from "../Functions/Functions";
import styled from "styled-components";

const UserMedia = () => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state.data?.[0]?.userId, "media-------");
  const [data, setData] = useState(location.state.data);
  const [openActiveDeactive, setOpenActiveDeactive] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [parameter, setParameter] = useState({});
  const [sort, setSort] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleAllImagesList = async () => {
    setLoading(true);
    let res = await getAllImages(location.state.data?.[0]?.userId);
    if (res?.statusCode === 200) {
      setData(res?.user_details);
      setLoading(false);
      console.log(res, "resssssssssssssssssssssssssss");
    } else {
      setLoading(false);
      toast.error(
        res?.response?.data?.message || res?.message || res?.error,
        "Something went wrong"
      );
    }
  };

  useEffect(() => {
    // setData(location?.state?.data);
    handleAllImagesList();
  }, []);
  useEffect(() => {
    let arr = data?.sort((a, b) => a.photo_position - b.photo_position);
    setSort(arr, "sorted");
  }, [data]);

  // Function For Handle Delete Action
  const handleDelete = async () => {
    // eslint-disable-next-line no-unused-vars
    const id = parameter.req.photoId;
    let reqdel = {
      photoId: parameter.req.photoId,
      userId: parameter.req.userId,
    };
    // console.log(reqdel);
    setModalLoading(true);
    let res = await deleteImage(reqdel);
    if (res.statusCode === 200) {
      // setSort(res?.user_details);
      // let update = [];
      // data?.map((val) => {
      //   if (val?.id !== id) {
      //     update.push(val);
      //   }
      // );
      setData(res?.user_details);
      setModalLoading(false);
      setOpenActiveDeactive(false);
      handleAllImagesList();
      toast.success(toastMessage(res.message));
    } else {
      toast.error(toastMessage(res.message));
    }
  };
  const Year = new Date().getFullYear();

  // Function For Delete Action Modal
  const Delete = (payload) => {
    setOpenActiveDeactive(true);
    let para = {
      name: "Delete",
      body: "this image",
      req: payload,
    };
    setParameter(para);
  };

  return (
    <div>
      {openActiveDeactive && (
        <DeleteBlock
          open={openActiveDeactive}
          close={() => setOpenActiveDeactive(false)}
          confirmSumbit={handleDelete}
          parameter={parameter}
          loading={modalLoading}
        />
      )}
      <div className="filter_rowMedia p-3 pt-1 ps-1">
        {/* <div className="d-flex ps-2 pt-2"> */}
        <div className="w-75">
          <div className="d-flex">
            <BackIconContainer className="pt-2" onClick={() => navigate(-1)}>
              <img className="pe-0" src={BackIcon} alt="" />
            </BackIconContainer>
            <h3 className="ps-0 p-3 mb-0 pb-0">Media</h3>
          </div>
        </div>
        {/* <div className="w-50">
            <span className="d-flex justify-content-end pe-3 page_routes">
              <p className="pt-4 mb-0 dashboard_routes">
                <Link className="dashboard_route_link" to="/jigar/users">
                  Users
                </Link>
              </p>
              <img
                alt=""
                className="next_logo"
                style={{ paddingTop: "1.7rem" }}
                src={next}
              />
              <p className="pt-4 mb-0 reported_routes">Users Media</p>
            </span>
          </div> */}
        {/* </div> */}
      </div>
      {loading ? (
        <div className="LoaderWrapper">
          <div className="BackgroundLoader"></div>
        </div>
      ) : (
        <>
          {location.state.data.length !== 0 ? (
            <div
              style={{ minHeight: "503px" }}
              className="CardWrapperGroup pb-5"
            >
              {sort?.map((value, index) => (
                <div
                  style={{ objectFit: "cover" }}
                  className="cardWrapperDiv mt-4"
                >
                  <CardWrapper className="mt-3">
                    <img src={value?.image_url} alt="" />
                    <div className="d-flex" style={{ bottom: "0" }}>
                      <div className="m-0 p-2 pt-3 w-25 font-bold">
                        Image-{value?.photo_position + 1}
                      </div>
                      <div className="d-flex w-75 p-2 pe-1 justify-content-end ">
                        <a
                          style={{ textDecoration: "none" }}
                          target="_blank"
                          href={value?.image_url}
                          alt=""
                        >
                          <button className="btn btn-outline-success btn-sm fs-6">
                            View
                          </button>
                        </a>
                        {sort.length > 1 ? (
                          <button
                            onClick={() =>
                              Delete({
                                photoId: value.id,
                                userId: value.userId,
                              })
                            }
                            className="btn btn-outline-danger btn-sm ms-2 fs-6"
                          >
                            Delete
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </CardWrapper>
                </div>
              ))}
            </div>
          ) : (
            <div
              style={{ minHeight: "503px" }}
              className="d-flex align-items-sm-center justify-content-center"
            >
              <h1 className="align-item-center">No Images</h1>
            </div>
          )}
        </>
      )}
      <div
        style={{ bottom: "0" }}
        className="d-flex justify-content-center bg-light ps-3 pt-2"
      >
        <span className="d-flex justify-content-center">
          <h6 className="pt-2">{Year.toString()} Jigar.</h6>
          <p className="mt-1 ps-2">All rights reserved</p>
        </span>
      </div>
    </div>
  );
};

export default UserMedia;

const BackIconContainer = styled.span`
  img {
    width: 35px;
    height: 37px;
    cursor: pointer;
    font-size: 25px;
    padding: 0 6px 0 0;
    padding-top: 11px;
    transition: font-size;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
  }
  img:hover {
    color: red;
    font-size: 27px;
    overflow: hidden;
  }
`;
