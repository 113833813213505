import React, { Fragment, useEffect, useState } from "react";
import { UserDetailWrapper, DetailsWrapper } from "../../GlobalStyle";
import { getProfile, toastMessage } from "../Module/Functions/Functions";

import "../../Style/UserDetail.css";
import {
  gender,
  education,
  cell,
  joined,
  user_location,
  defautlMale,
  BackIcon,
  next,
} from "../../Utils/Images";

import { viewUserDetail } from "../../ApiCrud";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import JigarOnFireModal from "../Components/JigarOnFireModal";
import JigarTalaModal from "../Components/JigarTalaModal";
import SuperLikesModal from "../Components/SuperLikesModal";
import * as moment from "moment";
import styled from "styled-components";
const UserDetail = () => {
  // const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [jigarShowModal, setJigarShowModal] = useState(false);
  const [superLikesShowModal, setSuperLikesShowModal] = useState(false);
  const [userDetail, setUserDetail] = useState([]);
  const [loader, setLoader] = useState(false);
  const [jigarOn, setJigarOn] = useState({});
  // const [superLikesTran, setSuperLikesTran] = useState({});
  const location = useLocation();
  const navigate = useNavigate();

  // Function For Get Details Of User
  const getUserDetail = async () => {
    setLoader(true);
    let res = await viewUserDetail(location.state.id);
    if (res.statusCode === 200) {
      // console.log(res);
      setUserDetail(res.user_details);
      let arr = res.user_details.userData.jigarOnFireTransactions;
      setJigarOn(arr[0]);
      // setSuperLikesTran(res?.user_details?.superLikeTransaction);
      setLoader(false);
    } else {
      toast.error(toastMessage(res.message));
      setLoader(false);
    }
  };

  // useEffect Hook
  useEffect(() => {
    getUserDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Function For Get JigarTala Status
  const jigarTalaStatus = () => {
    if (userDetail?.userData?.subscription_status === "active") {
      return "JigarTala";
    } else if (userDetail?.userData?.subscription_status === "inactive") {
      return "Jigar";
    } else {
      return "";
    }
  };

  // Function For Testing
  // eslint-disable-next-line no-unused-vars
  const getPurchaseDate = () => {
    if (userDetail?.userData?.Subscriptions.length < 1) {
      return "n/a";
    } else {
      let date = userDetail?.userData?.Subscriptions[0]?.purchase_date;
      date = date.split(" ");

      let update = new Date(date[0]);
      return moment(update).format("MM/DD/YYYY");
    }
    // let update = date.split(" ");
    // return update[0].split("-").reverse().join("-");
    //  return userDetail?.userData?.subscription_status?.purchase_date
  };

  // Code For Get Country Code + Cell Number
  let cellnum;
  if (userDetail?.userData) {
    if (userDetail?.userData?.country_code === null) {
      cellnum = 0;
    } else if (userDetail?.userData?.country_code) {
      cellnum =
        userDetail?.userData?.country_code +
        " " +
        userDetail?.userData?.mobile_number;
    }
  }

  // Function For Get JigarTala Dates
  const getJigarDate = (date) => {
    let update;
    if (date.length > 10) {
      date = date.split(" ");
      update = new Date(date[0]);
    } else {
      // console.log(date);
      date = date.split("-");
      date = date.reverse().join("-");
      update = new Date(date);
    }

    return moment(update).format("MM/DD/YYYY");
  };

  // Function For Testing
  // eslint-disable-next-line no-unused-vars
  const getEndDate = () => {
    if (userDetail?.userData?.Subscriptions.length < 1) {
      return "n/a";
    } else {
      let date = userDetail?.userData?.Subscriptions[0]?.end_date;
      date = date.split(" ");
      let update = new Date(date[0]);
      return moment(update).format("MM/DD/YYYY");

      // let update = date.split(" ");
      // return update[0].split("-").reverse().join("-");
    }
  };

  // Function For Get Joined Date
  const getDate = () => {
    let date = userDetail?.userData?.createdAt;
    date = date.split("T");
    let update = new Date(date[0]);
    return moment(update).format("MM/DD/YYYY");
    // return update[0].split("-").reverse().join("/");
  };

  // Function For Get SuperLikes OR JigarOnFire Dates
  const dateofpurchase = (date) => {
    // console.log(date);
    let update = new Date(date * 1000);
    if (!date) {
      return "n/a";
    }

    return moment(update).format("MM/DD/YYYY");
  };

  const superlikeDate = (date) => {
    if (date) {
      let update = new Date(date);
      return moment(update).format("MM/DD/YYYY");
    } else {
      return "n/a";
    }
  };
  // Code For Get Email Of Users
  let emailtype;
  if (userDetail?.userData) {
    if (userDetail?.userData?.email === null) {
      emailtype = "n/a";
    } else if (userDetail?.userData?.email) {
      emailtype = userDetail?.userData?.email;
    }
  }
  return (
    <div className="">
      <div className="d-flex">
        <div className="w-75 pt-3">
          <div className="d-flex">
            <BackIconContainer className="pt-2" onClick={() => navigate(-1)}>
              <img className="pe-0" src={BackIcon} alt="" />
            </BackIconContainer>
            <h3 className="ps-0 p-3 mb-0 pb-0">User Profile</h3>
          </div>
        </div>
        <div className="col-md-5">
          <span className="d-flex justify-content-end pt-3 pe-3 page_routes">
            <p className="pt-4 mb-0 dashboard_routes">
              <Link className="dashboard_route_link" to={location.state.path}>
                {location.state.from}
              </Link>
            </p>
            <img
              alt=""
              style={{ paddingTop: "5.4%" }}
              className="next_logo mt-1"
              src={next}
            />
            <p className="pt-4 mb-0 reported_routes">User Profile</p>
          </span>
        </div>
      </div>
      {loader || userDetail.length === 0 ? (
        <div className="LoaderWrapper ">
          <div className="BackgroundLoader"></div>
        </div>
      ) : (
        <UserDetailWrapper className="container">
          <div className="row p-3 gap-4 ps-4 pt-1">
            <div className="col-md-3 userAbout">
              <div className="userImage pt-4">
                {userDetail?.userData?.Photos[0]?.image_url ? (
                  <div className="d-flex justify-content-center">
                    {console.log(userDetail?.userData, "userDetail")}
                    <img
                      style={
                        userDetail?.userData?.subscription_status == "active" &&
                        !userDetail?.userData?.enableByAdmin
                          ? { boxShadow: "0 0 0 3px #D6B357" }
                          : {}
                      }
                      src={getProfile(userDetail?.userData?.Photos)}
                      alt=""
                    />
                  </div>
                ) : userDetail?.userData?.BasicInfo?.gender === "Male" ? (
                  <div className="d-flex justify-content-center">
                    <img
                      style={{ objectFit: "cover" }}
                      src={defautlMale}
                      alt=""
                    />
                  </div>
                ) : (
                  <div className="d-flex justify-content-center">
                    <img
                      style={{ objectFit: "cover" }}
                      src={defautlMale}
                      alt=""
                    />
                  </div>
                )}
                <h3 className="text-center pt-1">
                  {userDetail?.userData?.BasicInfo?.name}
                </h3>
              </div>
              <hr className="m-0" />

              <div className="row pe-4 ">
                <div className="col-md-4 text-center likesstatus">
                  <p className="text-center m-1">
                    {userDetail?.userData?.liked_count}
                  </p>
                  <p className="mb-0">Like</p>
                </div>
                <div className="col-md-4 likesstatus">
                  <p className="text-center m-1">
                    {userDetail?.userData?.disliked_count}
                  </p>
                  <p className="mb-0">Dislike</p>
                </div>
                <div className="col-md-4 p-0 text-center likesstatus">
                  <p className="text-center m-1">
                    {userDetail?.userData?.superLikes
                      ? userDetail?.userData?.superLikes
                      : 0}
                  </p>
                  <p className="mb-0 p-0 ps-1">SuperLike</p>
                </div>
              </div>
              <hr className="m-0 mb-3" />
              <div className="row">
                <h4 className="p-0 ps-2 text-hover-primary">Basic Info</h4>
                <div className="col-md-12 p-0 ps-2 pe-2">
                  <span className="">
                    <img className="p-0 m-0 pe-1" src={gender} alt="" />
                    <strong className="">Gender</strong>
                  </span>
                  <p className="m-0 ps-1">
                    {userDetail?.userData?.BasicInfo?.gender}
                  </p>
                  <hr className="m-0 mt-1 mb-3" />
                  <span className="">
                    <img className="p-0 m-0 pe-1" src={education} alt="" />
                    <strong>Education</strong>
                  </span>
                  <p className="m-0 ps-1">
                    {userDetail?.userData?.BasicInfo?.education}
                  </p>
                  <hr className="m-0 mt-1 mb-3" />
                  <span className="">
                    <img className="p-0 m-0 pe-1" src={gender} alt="" />
                    <strong>Age</strong>
                  </span>
                  <p className="m-0 ps-1">
                    {userDetail?.userData?.BasicInfo?.age}
                  </p>
                  <hr className="m-0 mt-1 mb-3" />
                  {userDetail?.userData?.signup_type === 3 ? (
                    <Fragment style={{ width: "450px" }}>
                      {" "}
                      <span className="">
                        <img className="p-0 m-0 pe-1" src={cell} alt="" />
                        <strong>Email</strong>
                      </span>
                      <p
                        type="button"
                        className="bs-tooltip-top"
                        // class="btn btn-secondary"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={emailtype}
                        style={{
                          cursor: "context-menu",
                          width: "250px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {emailtype}
                      </p>
                      {/* <p
                        style={{
                          width: "250px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                        }}
                        className="m-0 ps-1"
                      >
                        {emailtype}
                      </p> */}
                    </Fragment>
                  ) : userDetail?.userData?.signup_type === 1 ? (
                    <>
                      {" "}
                      <span className="">
                        <img className="p-0 m-0 pe-1" src={cell} alt="" />
                        <strong>Login type</strong>
                      </span>
                      <p className="m-0 ps-1">Facebook</p>
                    </>
                  ) : userDetail?.userData?.signup_type === 2 ? (
                    <>
                      {" "}
                      <span className="">
                        <img className="p-0 m-0 pe-1" src={cell} alt="" />
                        <strong>Login Type</strong>
                      </span>
                      <p className="m-0 ps-1">Apple</p>
                    </>
                  ) : userDetail?.userData?.signup_type === 0 ? (
                    <>
                      {" "}
                      <span className="">
                        <img className="p-0 m-0 pe-1" src={cell} alt="" />
                        <strong>Cell Number</strong>
                      </span>
                      <p className="m-0 ps-1">{cellnum}</p>
                    </>
                  ) : null}
                  <hr className="m-0 mt-1 mb-3" />{" "}
                  <span className="">
                    <img className="p-0 m-0 pe-1" src={joined} alt="" />
                    <strong>Joined At</strong>
                  </span>
                  <p className="m-0 ps-1">{getDate()}</p>
                  <hr className="m-0 mt-1 mb-3" />
                  <span className="">
                    <img className="p-0 m-0 pe-1" src={user_location} alt="" />
                    <strong>Location</strong>
                  </span>
                  <p className="m-0 ps-1">
                    {userDetail?.userData?.BasicInfo?.current_location ===
                    "null"
                      ? "n/a"
                      : userDetail?.userData?.BasicInfo?.current_location}
                  </p>
                  <hr className="m-0 mt-1 mb-3" />
                  <span className="">
                    <img className="p-0 m-0 pe-1" src={user_location} alt="" />
                    <strong>Country</strong>
                  </span>
                  <p className="m-0 ps-1">
                    {userDetail?.userData?.BasicInfo?.origin_location}
                  </p>
                </div>
              </div>
            </div>
            <DetailsWrapper className="col-md h-100 ">
              <div
                className="userStatus"
                style={{ backgroundColor: "#e2efda" }}
              >
                <h3 className="ps-2 pt-1">Swipes</h3>
                <hr className="mt-1" />

                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Swipe Left</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {userDetail?.userData?.leftSwipe}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Swipe Right</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {userDetail?.userData?.rightSwipe}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Total Swipes</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {userDetail?.userData?.totalSwipe}
                    </h6>
                  </div>
                </div>
              </div>
              <div
                className="userStatus mt-3"
                style={{ backgroundColor: "#bed6ea" }}
              >
                <h3 className="ps-2 pt-1">Matches</h3>
                <hr className="mt-1" />

                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Matches</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {userDetail?.userData?.matches}
                    </h6>
                  </div>
                </div>
              </div>
              <div
                className="userStatus mt-3"
                style={{ backgroundColor: "#d0cece" }}
              >
                <h3 className="ps-2 pt-1">Track</h3>
                <hr className="mt-1" />

                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Reported Users</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {userDetail?.userData?.reportedUsersCount}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Was Disliked</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {userDetail?.userData?.disliked_count}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Was Liked</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {userDetail?.userData?.liked_count}
                    </h6>
                  </div>
                </div>
                {/* <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">
                      Number of App Usage(Total)
                    </strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">0</h6>
                  </div>
                </div> */}
              </div>
              <div
                className="userStatus mt-3"
                style={{ backgroundColor: "#fff2cc" }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <h3 className="ps-2 pt-1">JigarTala</h3>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end pe-3">
                    {jigarShowModal && (
                      <JigarTalaModal
                        show={jigarShowModal}
                        onhide={() => setJigarShowModal(false)}
                        data={userDetail?.userData?.Subscriptions}
                      />
                    )}
                    {userDetail?.userData?.Subscriptions.length > 1 ? (
                      <button
                        onClick={() => setJigarShowModal(true)}
                        className="btn btn-outline-danger btn-sm m-2 d-flex justify-content-end"
                      >
                        See all
                      </button>
                    ) : null}
                  </div>
                </div>
                {/* <h3 className="ps-2 pt-1">JigarTala</h3> */}
                <hr className="mt-1" />

                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Status</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {jigarTalaStatus()}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">JigarTala $</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {userDetail?.userData?.Subscriptions.length < 1
                        ? "0"
                        : `${userDetail?.userData?.Subscriptions[0]?.total_amount}`}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Purchase Date</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {userDetail?.userData?.Subscriptions.length > 0
                        ? getJigarDate(
                            userDetail?.userData?.Subscriptions[0]
                              ?.purchase_date
                          )
                        : "n/a"}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">End Date</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {userDetail?.userData?.Subscriptions.length > 0
                        ? getJigarDate(
                            userDetail?.userData?.Subscriptions[0]?.end_date
                          )
                        : "n/a"}
                    </h6>
                  </div>
                </div>
              </div>
              <div
                className="userStatus mt-3"
                style={{ backgroundColor: "#f1ff8d" }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <h3 className="ps-2 pt-1">JigarOnFire</h3>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end pe-3">
                    {showModal && (
                      <JigarOnFireModal
                        show={showModal}
                        onhide={() => setShowModal(false)}
                        data={userDetail?.userData?.jigarOnFireTransactions}
                      />
                    )}
                    {userDetail?.userData?.jigarOnFireTransactions?.length >
                    1 ? (
                      <button
                        onClick={() => setShowModal(true)}
                        className="btn btn-outline-danger btn-sm m-2 d-flex justify-content-end"
                      >
                        See all
                      </button>
                    ) : null}
                  </div>
                </div>
                <hr className="mt-1" />

                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Amount $</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {jigarOn?.actual_amount ? jigarOn?.actual_amount : "0"}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Purchase date</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {jigarOn !== {} &&
                        dateofpurchase(jigarOn?.dateofpurchase)}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Type</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {jigarOn?.purchase_type === 2
                        ? "JigarOnFire Worldwide"
                        : jigarOn?.purchase_type === 1
                        ? "JigarOnFire Local"
                        : "n/a"}
                    </h6>
                  </div>
                </div>
              </div>
              <div
                className="userStatus mt-3"
                style={{ backgroundColor: "#fce4d6" }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <h3 className="ps-2 pt-1">SuperLikes</h3>
                  </div>
                  <div className="col-md-6 d-flex justify-content-end pe-3">
                    {superLikesShowModal && (
                      <SuperLikesModal
                        show={superLikesShowModal}
                        onhide={() => setSuperLikesShowModal(false)}
                        data={userDetail?.SuperLikesTransaction}
                      />
                    )}
                    {userDetail?.SuperLikesTransaction.length > 1 ? (
                      <button
                        onClick={() => setSuperLikesShowModal(true)}
                        className="btn btn-outline-danger btn-sm m-2 d-flex justify-content-end"
                      >
                        See all
                      </button>
                    ) : null}
                  </div>
                </div>
                {/* <h3 className="ps-2 pt-1">SuperLikes</h3> */}
                <hr className="mt-1" />

                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">SuperLikes Purchased</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {userDetail?.SuperLikesTransaction.length > 0
                        ? userDetail?.SuperLikesTransaction[0]
                            ?.numberofSuperLikesPurchased
                        : "0"}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">SuperLike $</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {userDetail?.SuperLikesTransaction.length > 0
                        ? userDetail?.SuperLikesTransaction[0]?.amount
                        : "0"}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Purchased (Date)</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {userDetail?.SuperLikesTransaction.length > 0
                        ? superlikeDate(
                            userDetail?.SuperLikesTransaction[0]?.createdAt
                          )
                        : "n/a"}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Paid SuperLikes(Used)</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {userDetail?.userData?.paidSuperLikeUsed
                        ? userDetail?.userData?.paidSuperLikeUsed
                        : "0"}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Paid SuperLikes(Left)</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {userDetail?.userData?.paidSuperLikesLeft
                        ? userDetail?.userData?.paidSuperLikesLeft
                        : "0"}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Free SuperLikes(Used)</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {userDetail?.userData?.freeSuperLikeUsed
                        ? userDetail?.userData?.freeSuperLikeUsed
                        : "0"}
                    </h6>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 pt-1">
                    <strong className="mt-3 ps-2">Free SuperLikes(Left)</strong>
                  </div>
                  <div className="col-md-6 ">
                    <h6 className="pt-2 d-flex justify-content-end pe-2">
                      {/* {userDetail?.userData?.freeSuperLikesLeft
                        ? userDetail?.userData?.freeSuperLikesLeft
                        : "0"} */}
                      {userDetail?.userData?.freeSuperLikesLeft !== null
                        ? userDetail?.userData?.freeSuperLikesLeft
                        : 0}
                    </h6>
                  </div>
                </div>
              </div>
            </DetailsWrapper>
          </div>
        </UserDetailWrapper>
      )}
    </div>
  );
};

export default UserDetail;

const BackIconContainer = styled.span`
  img {
    width: 30px;
    height: 35px;
    cursor: pointer;
    font-size: 25px;
    padding: 0 6px 0 0;
    padding-top: 16px;
    transition: font-size;
    transition-duration: 0.2s;
    transition-timing-function: ease-in;
  }
  img:hover {
    color: red;
    font-size: 27px;
    overflow: hidden;
  }
`;
