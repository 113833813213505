/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReportedUsersWrapper } from "../../../GlobalStyle";
import "../../../Style/Reported_users.css";
import {
  next,
  trash,
  reportaction,
  info,
  defautlMale,
} from "../../../Utils/Images";
import { toast } from "react-toastify";
import {
  blockUser,
  deleteUser,
  reportedUsers,
  searchReportedUsers,
} from "../../../ApiCrud";
import DeleteBlock from "../../Components/DeleteBlock";
import {
  getProfile,
  getSignupType,
  toastMessage,
} from "../Functions/Functions";
import MultipleDeleteModal from "../../Components/MultipleDelete";
import { SelectMultipleUsersActions } from "../../../Utils/SelectMultipleUsersActions";

const Reported_users = () => {
  const [users, setUsers] = useState([]);
  const [reportUsers, setReportUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [limit, setLimit] = useState(100);
  const [modalLoading, setModalLoading] = useState(false);
  const [parameter, setParameter] = useState({});
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [page, setPage] = useState(1);
  const [openActiveDeactive, setOpenActiveDeactive] = useState(false);
  const [multipleModal, setMultipleModal] = useState(false);
  const [multipleBlockModal, setMultipleBlockModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Function For Get Reported Users Listing
  const getReportedUsers = async () => {
    setLoading(true);
    let res = await reportedUsers(limit, page);
    if (res.statusCode === 200) {
      setUsers(res.user_details?.totalReportedUsers);
      setReportUsers(res.user_details?.allReportedUsers);
      setLoading(false);
    } else {
      toast.error(toastMessage(res.message));
      setLoading(false);
    }
    // console.log(users);
  };

  // Function for Page Two And Next Page Search
  const getSearchWord = async (word) => {
    let s = word;
    setSearch(true);
    setLoading(true);
    if (s) {
      let res = await searchReportedUsers(limit, page, {
        search: word,
      });
      if (res.statusCode === 200) {
        setReportUsers(res.user_details.allReportedUsers);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(toastMessage(res.message));
      }
    }
    if (!s) {
      setReportUsers([]);
      getReportedUsers();
    }
  };

  const handleAddRem = (obj, type) => {
    console.log(type, "type");
    if (type) {
      if (selectedRowsData?.length > 0) {
        if (Array.isArray(selectedRowsData)) {
          if (selectedRowsData.find((el) => el?.id === obj?.id)) {
            return selectedRowsData.map((el) => {
              if (el.key === obj.key) {
                setSelectedRowsData(obj);
              }
              setSelectedRowsData(el);
            });
          } else {
            setSelectedRowsData([...selectedRowsData, obj]);
          }
        } else {
          setSelectedRowsData([obj]);
        }
      } else {
        setSelectedRowsData([obj]);
      }
    } else {
      let arr = selectedRowsData.filter((el) => el?.id !== obj?.id);
      setSelectedRowsData(arr);
    }
  };

  console.log(selectedRowsData, "selectedRowsData");

  // Function For Navigate To User Detail Page
  const handleProfile = (ind) => {
    navigate("/jigar/userdetail", {
      state: { id: ind, path: location.pathname, from: "Reported Users" },
    });
  };

  // Function For Navigate To Edit Profile Page
  const handleedit = (value) => {
    return navigate("/jigar/editprofile", {
      state: { data: value, path: location.pathname, from: "Reported Users" },
    });
  };

  // Function For Get Status Of User
  const getStatus = (data) => {
    if (data.hides_user.is_blocked === true) {
      return "Blocked";
    }
    if (data.hides_user.is_deleted === true) {
      return "Deleted";
    }
    return "Active";
  };

  // useEffect Hook
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (searchVal.length === 0) {
      setPage(1);
      getReportedUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVal]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (!search && searchVal.length === 0) {
      getReportedUsers();
    }
    if (setSearchVal.length > 0 && page > 1) {
      getSearchWord(searchVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit]);

  // Function for Search Actions
  const handleSearch = (e) => {
    e.preventDefault();
    setPage(1);
    getSearchWord(searchVal);
  };

  // Function For Handle Delete OR Block Actions
  const handleDelete = async () => {
    setModalLoading(true);
    let id = parameter.id;
    let obj = {
      userIds: [id],
    };
    if (parameter.name === "Delete") {
      let res = await deleteUser(obj);

      if (res.statusCode === 200) {
        let update = [];
        reportUsers?.map((val) => {
          if (val.id !== id) {
            update.push(val);
          }
        });
        setReportUsers(update);
        setModalLoading(false);
        setOpenActiveDeactive(false);
        getReportedUsers();
        toast.success(toastMessage(res.message));
      } else {
        setModalLoading(false);
        toast.error(toastMessage(res.message));
      }
    } else {
      // let res;
      // let user = { userId: id };
      let res = await blockUser(obj);
      if (res.statusCode === 200) {
        setModalLoading(false);
        setOpenActiveDeactive(false);
        toast.success(toastMessage(res.message));
        getReportedUsers();
      } else {
        setModalLoading(false);
        toast.error(toastMessage(res.message));
      }
    }
  };

  const handleMultipleDelete = async (type) => {
    setModalLoading(true);
    let id = parameter.id;
    let arr = [];
    Array.isArray(selectedRowsData) && selectedRowsData?.length > 0
      ? selectedRowsData?.map((val) => {
          arr.push(val?.id);
        })
      : arr.push(id);

    let obj = {
      userIds: arr,
    };
    if (multipleModal) {
      let res = await deleteUser(obj);

      if (res.statusCode === 200) {
        let update = [];
        reportUsers?.map((val) => {
          if (val.id !== id) {
            update.push(val);
          }
        });
        setReportUsers(update);
        setModalLoading(false);
        setMultipleModal(false);
        toast.success(toastMessage(res.message));
        getReportedUsers();
      } else {
        setModalLoading(false);
        toast.error(toastMessage(res.message));
      }
    } else {
      // let res;
      // let user = { userId: id };
      let res = await blockUser(obj);
      if (res.statusCode === 200) {
        setModalLoading(false);
        setMultipleBlockModal(false);
        toast.success(toastMessage(res.message));
        getReportedUsers();
      } else {
        setModalLoading(false);
        toast.error(toastMessage(res.message));
      }
    }
  };

  const Year = new Date().getFullYear();

  // Function For Delete Modal
  const Delete = (payload) => {
    setOpenActiveDeactive(true);
    let para = {
      name: "Delete",
      body: "this user",
      id: payload,
    };
    setParameter(para);
  };

  // Function For Block Modal
  const Block = (payload) => {
    setOpenActiveDeactive(true);
    let para = {
      name: "Block",
      body: "this user",
      id: payload,
    };
    // console.log(para);
    setParameter(para);
  };

  return (
    <>
      {openActiveDeactive && (
        <DeleteBlock
          open={openActiveDeactive}
          close={() => setOpenActiveDeactive(false)}
          confirmSumbit={handleDelete}
          parameter={parameter}
          loading={modalLoading}
        />
      )}

      {multipleModal && (
        <MultipleDeleteModal
          open={multipleModal}
          close={() => setMultipleModal(false)}
          confirmSumbit={handleMultipleDelete}
          type={"Delete"}
          loading={modalLoading}
        />
      )}
      {multipleBlockModal && (
        <MultipleDeleteModal
          open={multipleBlockModal}
          close={() => setMultipleBlockModal(false)}
          confirmSumbit={handleMultipleDelete}
          type={"Block"}
          loading={modalLoading}
        />
      )}

      <ReportedUsersWrapper>
        <div className="d-flex pt-2">
          <div className="w-50">
            <h3 className="pt-3 ps-2 mb-0 text-xl-start">Reported Users</h3>
          </div>
          <div className="w-50">
            <span className="d-flex justify-content-end pe-3 page_routes">
              <p className="pt-4 mb-0 dashboard_routes">
                <Link className="dashboard_route_link" to="/jigar/users">
                  Users
                </Link>
              </p>
              <img alt="" className="next_logo" src={next} />
              <p className="pt-4 mb-0 reported_routes">Reported Users</p>
            </span>
          </div>
        </div>

        <div className="reported_users">
          <div className="d-flex filter_row p-4 ps-0">
            <div className="w-50 d-flex">
              <span className="mt-1 ps-3">Show</span>
              <select
                className="reported_entries_option ps-2 ms-2"
                onChange={(e) => {
                  setPage(1);
                  setLimit(e.target.value);
                }}
              >
                {/* <option value="50">50</option> */}
                <option value="100">100</option>
                <option value="500">500</option>
                <option value="1000">1000</option>
              </select>
              <span className="mt-1 ms-2">entries</span>
            </div>
            <div className="w-50 d-flex justify-content-end">
              <form onSubmit={handleSearch}>
                <label>Search :</label>
                <input
                  autoFocus
                  value={searchVal}
                  disabled={loading}
                  onChange={(e) => setSearchVal(e.target.value)}
                  className="ms-1 serach_users"
                  type="text"
                />
              </form>
            </div>
          </div>

          {selectedRowsData?.length > 0 && (
            <SelectMultipleUsersActions
              handleDelete={handleMultipleDelete}
              setMultipleModal={setMultipleModal}
              setMultipleBlockModal={setMultipleBlockModal}
            />
          )}

          {loading ? (
            <div className="LoaderWrapper">
              <div className="BackgroundLoader"></div>
            </div>
          ) : (
            <div style={{ minHeight: "365px" }} className="reported_table">
              <table className="">
                {/* <div className="w-100">               */}
                <thead className="TableHeader1">
                  <tr className="table_row">
                    <th className="pt-3 pb-2">
                      <span className="span_reported"> </span>
                    </th>
                    <th className="pt-3 pb-2">
                      <span className="span_reported">#</span>
                    </th>
                    <th className="pt-3 pb-2">
                      <span className="span_reported">Thumbnail </span>
                    </th>
                    <th className="pt-3 pb-2">
                      <span className="span_reported">
                        Reported Username{" "}
                        {/* <img
                          alt=""
                          className="sort1 mt-auto mb-auto"
                          src={sort}
                          // onClick={() => sortedAge("user")}
                        /> */}
                      </span>
                    </th>
                    <th className="pt-3 pb-2">
                      <span className="span_reported">
                        Login type
                        {/* <img
                        alt=""
                        className="sort1 mt-auto mb-auto"
                        src={sort}
                      /> */}
                      </span>
                    </th>
                    <th className="pt-3 pb-2">
                      <span className="span_reported">
                        Reported Type
                        {/* <img
                          alt=""
                          className="sort1 mt-auto mb-auto"
                          src={sort}
                        /> */}
                      </span>
                    </th>
                    <th className="pt-3 pb-2">
                      <span className="span_reported">
                        Reported by Username
                        {/* <img
                          alt=""
                          className="sort1 mt-auto mb-auto"
                          src={sort}
                        /> */}
                      </span>
                    </th>
                    <th className="pt-3 pb-2">
                      <span className="span_reported">
                        Reported by Login type
                        {/* <img
                          alt=""
                          className="sort1 mt-auto mb-auto"
                          src={sort}
                        /> */}
                      </span>
                    </th>
                    <th className="pt-3 pb-2 text-center">
                      Status
                      {/* <img
                          alt=""
                          className="sort1 mt-auto mb-auto"
                          src={sort}
                        /> */}
                    </th>

                    <th className="pt-3 pb-2 text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {reportUsers?.length > 0 ? (
                    reportUsers?.map((value, ind) => (
                      <tr>
                        <td className="reported_tableData px-4">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              handleAddRem(value, e.target.checked)
                            }
                          />
                        </td>
                        <td className="reported_tableData ps-3">
                          {" "}
                          {page > 1 ? limit * (page - 1) + (1 + ind) : ind + 1}
                        </td>
                        <td className="reported_tableData">
                          {value?.hides_user?.Photos?.length > 0 ? (
                            <img
                              // style={{objectFit: "fill"}}
                              alt=""
                              className="tableDataimg ms-2"
                              src={getProfile(value?.hides_user?.Photos)}
                            />
                          ) : (
                            <img
                              style={{ objectFit: "cover" }}
                              alt=""
                              className="tableDataimg ms-2"
                              src={defautlMale}
                            />
                          )}
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          className="reported_tableData"
                        >
                          <span
                            className="name_borders ps-2 pe-2 text-hover-primary"
                            onClick={() => handleedit(value?.hides_user)}
                          >
                            {value?.hides_user?.BasicInfo.name}
                          </span>
                        </td>
                        <td className="reported_tableData">
                          {" "}
                          {value?.hides_user?.signup_type === 0
                            ? `${
                                value?.hides_user?.country_code !== null
                                  ? `(${value?.hides_user?.country_code})`
                                  : ""
                              }${value?.hides_user?.mobile_number}`
                            : getSignupType(value?.hides_user?.signup_type)}
                        </td>
                        <td className="reported_tableData">
                          {value.message !== ""
                            ? value.message
                            : "Hide by user"}
                        </td>
                        <td
                          className="reported_tableData text-hover-primary"
                          onClick={() => handleedit(value?.hides)}
                        >
                          <span style={{ borderBottom: "1px solid #ccc" }}>
                            {value.hides.BasicInfo.name}
                          </span>
                        </td>
                        <td className="reported_tableData">
                          {" "}
                          {value?.hides?.signup_type === 0
                            ? `${
                                value?.hides?.country_code !== null
                                  ? `(${value?.hides?.country_code})`
                                  : ""
                              }${value?.hides?.mobile_number}`
                            : getSignupType(value?.hides?.signup_type)}
                        </td>
                        <td className="reported_tableData">
                          {value?.hides_user?.is_blocked === true ||
                          value?.hides_user?.is_deleted === true
                            ? getStatus(value)
                            : value?.hides_user?.is_profile_completed === true
                            ? "Active"
                            : "Inactive"}
                        </td>
                        <td className="reported_tableData ps-2 pe-3">
                          <div className="d-flex action_btn_group pb-2">
                            <button
                              onClick={() => handleProfile(value.hide_user_id)}
                              className="action_info"
                            >
                              <img className="mt-1" alt="" src={info} />
                            </button>
                            <button
                              onClick={() => Block(value?.hide_user_id)}
                              className="action_report"
                            >
                              {" "}
                              <img className="mt-1" alt="" src={reportaction} />
                            </button>
                            <button
                              onClick={() => Delete(value?.hide_user_id)}
                              className="action_delete"
                            >
                              {" "}
                              <img className="mt-1" alt="" src={trash} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        width: "96%",
                        paddingTop: "15%",
                      }}
                      className="d-flex justify-content-center text-success ps-3 fs-2"
                    >
                      No Data Found
                    </div>
                  )}
                </tbody>
                {/* </div> */}
              </table>
            </div>
          )}
          <div>
            <div className="d-flex pt-5 ps-3">
              <div className="w-50 pt-2">
                <p style={{ fontSize: "14px" }}>
                  Showing {page > 1 ? (page - 1) * limit + 1 : 1} to{" "}
                  {page > 1
                    ? limit * page
                    : reportUsers?.length >= limit
                    ? limit
                    : reportUsers?.length}{" "}
                  of {users} entries
                </p>
              </div>
              <div className="w-50">
                <div className="d-flex justify-content-end">
                  <div className="pagination me-5">
                    <ul>
                      {page > 1 && (
                        <li
                          onClick={() => {
                            if (!loading) {
                              setPage(page - 1);
                            }
                          }}
                        >
                          Previous
                        </li>
                      )}
                      {reportUsers?.length <= limit && page > 1 && (
                        <li
                          onClick={() => {
                            if (!loading) {
                              setPage(page - 1);
                            }
                          }}
                        >
                          {reportUsers?.length <= limit ? page - 1 : ""}
                        </li>
                      )}
                      <li className="active">{page}</li>
                      {reportUsers?.length >= limit && (
                        <li
                          onClick={() => {
                            if (!loading) {
                              setPage(page + 1);
                            }
                          }}
                        >
                          {reportUsers?.length >= limit ? page + 1 : ""}
                        </li>
                      )}
                      {reportUsers?.length >= limit && (
                        <li
                          onClick={() => {
                            if (!loading) {
                              setPage(page + 1);
                            }
                          }}
                        >
                          {reportUsers?.length >= limit ? "Next" : ""}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="d-flex justify-content-center "
          style={{
            backgroundColor: "#ecf0f5",
          }}
        >
          <span className="d-flex justify-content-center">
            <span style={{ fontWeight: "500", fontSize: "14px" }}>
              {Year.toString()} Jigar.
            </span>
            <p style={{ fontSize: "14px" }} className="ps-2">
              All rights reserved
            </p>
          </span>
        </div>
      </ReportedUsersWrapper>
    </>
  );
};

export default Reported_users;
