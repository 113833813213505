import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { next } from "../Utils/Images";

const AddUsers = () => {
  const initialValues = {
    name: "",
    bio: "",
    location: "",
    country: "",
    age: "",
    gender: "",
    countrycode: "",
    cellnumber: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    location: Yup.string().required("Location required"),
    country: Yup.string().required("Required"),
    age: Yup.string().required("Required"),
  });
  const Year = new Date().getFullYear()


  const onSubmit = (values) => {
    // console.log("Data", values);
  };
  return (
    <div className="p-3 pb-2">
      <div className="row">
        <div className="col-md-7 pt-3">
          <h3 className="mb-0">Add user</h3>
        </div>
        <div className="col-md-5">
          <span className="d-flex justify-content-end pe-3 page_routes">
            <p className="pt-4 mb-0 dashboard_routes">
              <Link className="dashboard_route_link" to="/jigar">
                Users
              </Link>
            </p>
            <img alt="" className="next_logo mt-2" src={next} />
            <p className="pt-4 mb-0 reported_routes">Add user</p>
          </span>
        </div>
      </div>
      <div className="row p-3 userProfile_row">
        <div className="col-md-12 userProfile p-3 pt-1 pe-0 ps-0">
          <hr />
          <div className="p-3">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form>
                <div className="form-group mb-2">
                  <label>Name</label>
                  <br />
                  <Field
                    className="editinputs"
                    type="text"
                    placeholder="Name"
                    name="name"
                  />
                  <div className="text-danger">
                    <ErrorMessage name="name" />
                  </div>
                </div>
                <div className="form-group mb-2">
                  <label>Bio</label>
                  <br />
                  <Field as="textarea" name="bio" className="bio_textarea" />
                </div>
                <div className="form-group pt-1 mb-2">
                  <label>Location(city, state)</label>
                  <br />
                  <Field
                    className="editinputs"
                    type="text"
                    placeholder="Location"
                    name="location"
                  />
                  <div className="text-danger">
                    <ErrorMessage name="location" />
                  </div>
                </div>
                <div className="form-group pt-1 mb-2">
                  <label>Country</label>
                  <br />
                  <Field
                    className="editinputs"
                    type="text"
                    placeholder="Country"
                    name="country"
                  />

                  <div className="text-danger">
                    <ErrorMessage name="country" />
                  </div>
                </div>
                <div className="form-group pt-1 mb-2">
                  <label>Age</label>
                  <br />
                  <Field
                    className="editinputs"
                    type="text"
                    placeholder="Age"
                    name="age"
                  />
                  <div className="text-danger">
                    <ErrorMessage name="age" />
                  </div>
                </div>
                <div className="form-group pt-1 mb-2">
                  <label>Gender</label>
                  <br />
                  <Field name="gender" as="select" className="editinputs">
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </Field>
                  <div className="text-danger">
                    <ErrorMessage name="gender" />
                  </div>
                </div>
                <div className="row pt-2">
                  <div className="col-md-3">
                    <div className="form-group pt-1 mb-2">
                      <label>Country Code</label>
                      <br />
                      <Field
                        className="editinputs"
                        type="text"
                        placeholder="Add Country Code"
                        name="countrycode"
                      />
                      <div className="text-danger">
                        <ErrorMessage name="countrycode" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div className="form-group pt-1 mb-2">
                      <label>Cell Number</label>
                      <br />
                      <Field
                        className="editinputs"
                        type="text"
                        placeholder="Add Cell Number"
                        name="cellnumber"
                      />
                      <div className="text-danger">
                        <ErrorMessage name="cellnumber" />
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="mt-4" />
                <div className="row pt-2">
                  <div className="col-md-1">
                    <button type="submit" className="btn btn-primary">
                      Add
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <div className="row d-flex justify-content-center ps-3 pt-1">
        <span className="d-flex justify-content-center">
          <h6 className="pt-4">{Year.toString()}  Jigar.</h6>
          <p className="ps-2 footer_about">All right reserved</p>
        </span>
      </div>
    </div>
  );
};

export default AddUsers;
