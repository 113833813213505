import styled from "styled-components";
// const [windowSize, setWindowSize] = useState(getWindowSize());
export const LoginWrapper = styled.div`
  background-color: #f3f4f6;
  width: 100%;
  height: 100vh;
  min-height: 500px;

  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Poppins;
  .contnet-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
`;

export const AppLogo = styled.div`
  padding-top: 30px;
  text-align: center;
  padding-bottom: 20px;
  img {
    margin: 0 auto;
    width: 100px;
  }
`;

export const FormWrapper = styled.div`
  width: 420px;
  max-width: 100%;
  background-color: white;
  border-radius: 10px;
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;

export const LandingWrapper = styled.div`
  width: 100%;
`;
export const SideDivWrapper = styled.div`
  width: 16%;
  height: 100vh;
  background-color: #222d32;
  position: fixed;
  top: 0;
`;

export const DashboardWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const DashboardHeader = styled.div`
  width: 100%;
  .user_group_logo {
    background-color: #f39c12;
  }
  .user_group_logo img {
    -webkit-filter: invert(0.9);
    filter: invert(0.9);
  }
`;
export const ReportedUsersWrapper = styled.div`
  font-family: Poppins;
  padding: 0 !important;
`;

export const DeletedUsersWrapper = styled.div`
  font-family: Poppins;
  padding: 0 !important;
  overflow-x: hidden;
`;

export const TableData = styled.td`
  border-right: 0.3px solid rgb(235, 232, 232) !important;
  padding: 1rem;
  padding-bottom: 0;
  width: max-content;
  font-size: 13px;
  text-align: center;
`;
export const TableId = styled.td`
  ${"" /* border-right: 1px solid lightgray !important; */}
  padding-bottom: 0;
  font-size: 13px;
`;
export const InfoWrapper = styled.div`
  padding: 0px 0px;
  display: flex;
  justify-content: center;

  div {
    width: 100%;
    text-align: center;
    ${"" /* margin: 0 20px 0 20px; */}
  }

  @media (max-width: 682px) {
    flex-direction: column;
  }
`;

export const InfoValue = styled.span`
  // font-family: "Inter";
  font-style: normal;
  font-size: 18px;
  width: 50%;
  letter-spacing: -0.04em;
  font-weight: 500;
  line-height: 24px;
  color: #000000;

  div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin: 0;
  }

  img {
    width: 70px;
    height: 70px;
    margin: 10px;
    border-radius: 10px;
    transition: transform 0.5s ease;
  }
  img:hover {
    transform: scale(1.5);
  }
`;

export const InfoLabel = styled.label`
  width: 50%;
  display: flex;
  justify-content: center;
  // font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #757575;
`;

export const InfoLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
`;

export const Input = styled.input`
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #e6e6f1;
  box-sizing: border-box;
  border-radius: 37px;
  // margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 20px;

  ::placeholder {
    // font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #acacac;
  }
`;

export const UserDetailWrapper = styled.div`
  background-color: #f1f3fa;
  padding-top: 20px;
`;
export const DetailsWrapper = styled.div`
  height: max-content !important;
`;

export const LinkWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
export const CardWrapper = styled.div`
  height: 100%;
  width: 100%;
  border: 1px solid #cecece;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 7px;
  background-size: cover !important;

  img {
    height: 89%;
    border-radius: 7px;
    width: 100%;

    ${"" /* background-size: cover !important; */}
  }
  :hover {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 5px 5px;
  }
`;
export const ShowDate = styled.div`
  position: relative;
  :hover .popup {
    opacity: 1;
    display: block;
  }
  .icon {
    border: 1px solid #cecece;
    border-radius: 5px;
    padding: 2px 5px;
    width: fit-content;
  }
  .popup {
    border: 1px solid #efefef;
    max-width: 130px;
    max-height: 100px;
    overflow-y: scroll;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    padding: 1px 20px;
    position: absolute;

    transition: all 0.25s ease;
    opacity: 0;
    top: -20px;
    margin-left: -10px;
    padding: 12px 12px;
    border-radius: 0.4em;
    background-color: #fff;
    display: none;
  }
  .popup:hover {
    display: block;
  }
`;
