/* eslint-disable eqeqeq */
import React from "react";
import styled from "styled-components";

export const SelectMultipleUsersActions = ({
  handleDelete,
  setMultipleModal,
  setMultipleBlockModal,
}) => {
  const location = window?.location;

  console.log(location?.pathname, "location");
  return (
    <MultipleUserAction>
      <button
        style={{
          backgroundColor: "#00c0ef",
          color: "white",
          cursor: "pointer",
        }}
        className="btn"
        onClick={() => setMultipleModal(true)}
      >
        Delete
      </button>
      <button
        style={{
          backgroundColor: "#00c0ef",
          color: "white",
          cursor: "pointer",
        }}
        className="btn"
        onClick={() => setMultipleBlockModal(true)}
      >
        {location?.pathname == "/jigar/blocked"
          ? "Unblock"
          : location?.pathname == "/jigar/jigartala-enabled"
          ? "Cancel Subscri..."
          : "Block"}
      </button>
    </MultipleUserAction>
  );
};
const MultipleUserAction = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: end;
  padding-right: 70px;
  margin-bottom: 6px;
  button {
    padding: 5px 10px;
    fontfamily: Poppins;
  }
`;
