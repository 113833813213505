import * as moment from "moment";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { InfoWrapper, InfoLabel, InfoValue, InfoLine } from "../../GlobalStyle";

const JigarTalaModal = ({ show, onhide, data }) => {
  const dateofpurchase = (date) => {
    let update;
    if (date.length > 10) {
      date = date.split(" ");
      update = new Date(date[0]);
    } else {
      // console.log(date);
      date = date.split("-");
      date = date.reverse().join("-");
      update = new Date(date);
    }

    return moment(update).format("MM/DD/YYYY");
  };
  const style = {
    position: "absolute",
    right: "-20px",
    top: "-20px",
    width: "50px",
    height: "50px",
    background: "red",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffffff",
    fontSize: "28px",
    zIndex: "1",
    cursor: "pointer",
  };
  return (
    <div>
      <Modal show={show} onHide={onhide}>
        <span style={style} onClick={onhide}>
          &#10008;
        </span>
        <Modal.Header>
          <Modal.Title className="modalTitle">JigarTala</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InfoWrapper>
            <div>
              <InfoLine>
                {/* <InfoLabel>Status</InfoLabel> */}
                <InfoLabel>Amount</InfoLabel>
                <InfoLabel>Purchase Date</InfoLabel>
                <InfoLabel>End Date</InfoLabel>
              </InfoLine>
              <div style={{ overflowY: "scroll", maxHeight: "380px" }}>
                {data.length > 0 ? (
                  data.map((value) => (
                    <>
                      <InfoLine>
                        {/* <InfoValue>$ {value?.actual_amount}</InfoValue> */}
                        <InfoValue>{value?.actual_price}</InfoValue>
                        <InfoValue>
                          {dateofpurchase(value.purchase_date)}
                        </InfoValue>
                        <InfoValue>{dateofpurchase(value.end_date)}</InfoValue>
                      </InfoLine>
                      <hr className="m-0 p-0"></hr>
                    </>
                  ))
                ) : (
                  <span className="text-danger font-weight-bold">No Data</span>
                )}
              </div>
            </div>
          </InfoWrapper>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default JigarTalaModal;
