import React from "react";
import "../Style/ErrorPage.css";
import { useNavigate } from "react-router-dom";

const ErrorPage = () => {
  const navigate = useNavigate()
  return (
    <div>
      <h1 className="pt-5 text-center">404 Page Not Found</h1>
      <section className="error-container">
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
        <span className="zero">
          <span className="screen-reader-text">0</span>
        </span>
        <span className="four">
          <span className="screen-reader-text">4</span>
        </span>
      </section>
      <div className="link-container">
        <button onClick={() => navigate(-1)} className="more-link">Go Back</button>
      </div>
    </div>
  );
};

export default ErrorPage;
