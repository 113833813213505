import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  next,
  sort,
  defautlMale,
  sortAsc,
  sortDesc,
} from "../../../Utils/Images";
import { DeletedUsersWrapper, TableData, TableId } from "../../../GlobalStyle";
import { toast } from "react-toastify";
import * as moment from "moment";
import { deletedUsers, searchDeletedUsers } from "../../../ApiCrud";
import { useNavigate } from "react-router-dom";
import {
  getProfile,
  getSignupType,
  toastMessage,
} from "../Functions/Functions";
const Deleted_Users = () => {
  const location = useLocation();
  const [del_Users, setDel_Users] = useState([]);
  const [allDeleted, setAllDeleted] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const navigate = useNavigate();
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(1);
  const [sortParam, setSortParam] = useState({
    sortType: "",
    sortBasis: "",
  });

  // Function For Get Deleted Users Listing
  const getDeletedUsers = async () => {
    setLoading(true);
    let res = await deletedUsers(limit, page, sortParam);
    if (res.statusCode === 200) {
      setDel_Users(res.user_details?.allDeletedUsers);
      setAllDeleted(res.user_details?.totalDeletedUsers);
      setLoading(false);
    } else {
      toast.error(toastMessage(res.message));
      setLoading(false);
    }
  };

  // Function For Handle Search Actions
  const handleSearch = async (e) => {
    e.preventDefault();
    setPage(1);
    setLoading(true);

    let res = await searchDeletedUsers(limit, page, {
      search: searchVal,
    });
    if (res.statusCode === 200) {
      setDel_Users(res?.user_details?.allDeletedUsers);
      setLoading(false);
    } else {
      setLoading(false);
      toast.error(toastMessage(res.message));
    }
  };

  // Function For Testing Search
  const getSearchWord = async (word) => {
    let s = word;
    setSearch(true);
    if (s) {
      setLoading(true);
      let res = await searchDeletedUsers(limit, page, {
        search: word,
      });
      if (res.statusCode === 200) {
        setDel_Users(res?.user_details?.allDeletedUsers);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error(toastMessage(res.message));
      }
    }
    if (!s) {
      setAllDeleted([]);
      getDeletedUsers();
    }
  };

  const Year = new Date().getFullYear()

  // Function For Sort Icons Change ON Sorting Actions
  const srcSortImage = (Basis) => {
    if (Basis === sortParam.sortBasis) {
      if (sortParam.sortType === "ASC") {
        return sortAsc;
      } else {
        return sortDesc;
      }
    } else {
      return sort;
    }
  };

  // Function For Sorting Actions
  const getSortParam = (sortBasis) => {
    setSearchVal("");
    setPage(1);
    if (sortBasis === sortParam.sortBasis) {
      setSortParam({
        sortType: sortParam.sortType === "DESC" ? "ASC" : "DESC",
        sortBasis: sortBasis,
      });
    } else {
      setSortParam({
        sortType: "DESC",
        sortBasis: sortBasis,
      });
    }
  };

  // Function For Flip Data According to sort
  const sortingDataFlip = (sortBasis) => {
    setSearchVal("");
    setPage(1);
    if (sortBasis === sortParam.sortBasis) {
      setSortParam({
        sortType: sortParam.sortType === "ASC" ? "DESC" : "ASC",
        sortBasis: sortBasis,
        // timeDuration: duration,
      });
    } else {
      setSortParam({
        sortType: "ASC",
        sortBasis: sortBasis,
        // timeDuration: duration,
      });
    }
  };

  // Function For Navigate To User Detail Page
  const handleProfile = (ind) => {
    navigate("/jigar/userdetail", {
      state: { id: ind, path: location.pathname, from: "Deleted Users" },
    });
  };

  // Function For Get Status Of Deleted Users
  const getStatus = (data) => {
    if (data.deleted_by === 1) {
      return "User Deleted";
    }
    if (data.deleted_by === 2) {
      return "Admin Deleted";
    }
    return "";
  };

  // Function For Get Joined Date
  const getDate = (date) => {
    let update = new Date(date);
    return moment(update).format("MM/DD/YYYY");
  };

  // useEffect Hook
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (searchVal?.length === 0) {
      setPage(1);
      setSortParam({ sortBasis: "", sortType: "" });
    }
  }, [searchVal]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (!search && searchVal?.length === 0) {
      getDeletedUsers();
    }
    if (setSearchVal?.length > 0 && page >= 1) {
      getSearchWord(searchVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, sortParam]);

  return (
    <DeletedUsersWrapper>
      <div className="d-flex ps-2 pt-2">
        <div className="w-50">
          <h3 className="pt-3 ps-2 mb-0 text-xl-start">Deleted Users</h3>
        </div>
        <div className="w-50">
          <span className="d-flex justify-content-end pe-3 page_routes">
            <p className="pt-4 mb-0 dashboard_routes">
              <Link className="dashboard_route_link" to="/jigar/users">
                Users
              </Link>
            </p>
            <img alt="" className="next_logo" src={next} />
            <p className="pt-4 mb-0 reported_routes">Deleted Users</p>
          </span>
        </div>
      </div>
      <div className="dashboard_users">
        <div className="d-flex filter_row p-4 ps-0">
          <div className="w-50 d-flex">
            <span className="mt-1 ps-3">Show</span>
            <select
              className="reported_entries_option ps-2 ms-2"
              onChange={(e) => {
                setPage(1);
                setLimit(e.target.value);
              }}
            >
              {/* <option value="50">50</option> */}
              <option value="100">100</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
            </select>
            <span className="mt-1 ms-2">entries</span>
          </div>
          <div className="w-50 d-flex justify-content-end">
            <form onSubmit={handleSearch}>
              <label>Search :</label>
              <input
                autoFocus
                value={searchVal}
                disabled={loading}
                onChange={(e) => setSearchVal(e.target.value)}
                className="ms-1 serach_users"
                type="text"
              />
            </form>
          </div>
        </div>
        <div style={{ minHeight: "357px" }}>
          {loading ? (
            <div className="LoaderWrapper ">
              <div className="BackgroundLoader"></div>
            </div>
          ) : (
            <div className="dashboard_table p-0">
              <table className="">
                <thead className="TableHeader">
                  {/* <UserHeader /> */}
                  <tr className="table_row sticky-table-headers__sticky">
                    {/* Members Heading */}
                    <th className="members p-2 pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        #
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Thumbnail{" "}
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Users
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Deleted
                        <img
                          alt=""
                          className="sort1 mt-auto mb-auto ps-3"
                          src={srcSortImage("Date")}
                          onClick={() => sortingDataFlip("Date")}
                        />
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        M/F
                        <img
                          alt=""
                          className="sort1 mt-auto mb-auto"
                          src={srcSortImage("Gender")}
                          onClick={() => sortingDataFlip("Gender")}
                        />
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Location
                        {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Device
                        {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Status
                        {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Age
                        <img
                          alt=""
                          className="sort1 mt-auto mb-auto"
                          src={srcSortImage("Age")}
                          onClick={() => getSortParam("Age")}
                        />
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Login type
                        {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                      </span>
                    </th>
                    <th className="members pt-4 pb-4">
                      <span className="span1 d-flex justify-content-center">
                        Action
                        {/* <img alt="" className="sort1 mt-auto mb-auto" src={sort} /> */}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {del_Users?.length > 0 ? (
                    del_Users?.map((value, index) => (
                      <tr className="">
                        <TableId className="tableData pe-0">
                          {page > 1
                            ? limit * (page - 1) + (1 + index)
                            : index + 1}
                        </TableId>
                        <TableData className="tableData">
                          {value?.Photos?.length > 0 ? (
                            <img
                              alt=""
                              className="tableDataimg ms-2"
                              src={getProfile(value?.Photos)}
                            />
                          ) : (
                            <img
                              style={{ objectFit: "cover" }}
                              alt=""
                              className="tableDataimg ms-2"
                              src={defautlMale}
                            />
                          )}
                        </TableData>
                        <TableData className="tableData">
                          {value?.BasicInfo?.name}
                        </TableData>
                        <TableData className="tableData ps-2 pe-2">
                          {getDate(value?.updatedAt)}
                        </TableData>
                        <TableData className="tableData">
                          {value?.BasicInfo?.gender}
                        </TableData>
                        <TableData className="tableData">
                          {value?.BasicInfo?.current_location === "null"
                            ? null
                            : value?.BasicInfo?.current_location}
                        </TableData>
                        <TableData className="tableData">
                          {value?.platform}
                        </TableData>
                        <TableData className="tableData">
                          {getStatus(value)}
                        </TableData>
                        <TableData className="tableData">
                          {value?.BasicInfo?.age}
                        </TableData>
                        <TableData className="tableData">
                          {" "}
                          {value?.signup_type === 0
                            ? `${
                                value?.country_code !== null
                                  ? `(${value?.country_code})`
                                  : ""
                              }${value?.mobile_number}`
                            : getSignupType(value?.signup_type)}
                        </TableData>
                        <TableData className="tableData ps-2 pe-3">
                          <div className="d-flex action_btn_group">
                            <button
                              onClick={() => handleProfile(value.id)}
                              className="btn btn-outline-success btn-sm"
                            >
                              {" "}
                              Info
                            </button>
                          </div>
                        </TableData>
                      </tr>
                    ))
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        width: "96%",
                        paddingTop: "10%",
                      }}
                      className="d-flex justify-content-center text-success ps-3 fs-2"
                    >
                      No Data Found
                    </div>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="d-flex pt-5 ps-3">
          <div className="w-50 pt-2">
            <p style={{ fontSize: "14px" }}>
              Showing {page > 1 ? (page - 1) * limit + 1 : 1} to{" "}
              {page > 1
                ? limit * page
                : allDeleted?.length >= limit
                ? limit
                : allDeleted?.length}{" "}
              of {allDeleted} entries
            </p>
          </div>
          <div className="w-50">
            <div className="d-flex justify-content-end">
              <div className="pagination me-5">
                <ul>
                  {page > 1 && (
                    <li
                      onClick={() => {
                        if (!loading) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      Previous
                    </li>
                  )}
                  {del_Users?.length <= limit && page > 1 && (
                    <li
                      onClick={() => {
                        if (!loading) {
                          setPage(page - 1);
                        }
                      }}
                    >
                      {del_Users?.length <= limit ? page - 1 : ""}
                    </li>
                  )}
                  <li className="active">{page}</li>
                  {del_Users?.length >= limit && (
                    <li
                      onClick={() => {
                        if (!loading) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      {del_Users?.length >= limit ? page + 1 : ""}
                    </li>
                  )}
                  {del_Users?.length >= limit && (
                    <li
                      onClick={() => {
                        if (!loading) {
                          setPage(page + 1);
                        }
                      }}
                    >
                      {del_Users?.length >= limit ? "Next" : ""}
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-center "
        style={{
          backgroundColor: "#ecf0f5",
        }}
      >
        <span className="d-flex justify-content-center">
          <span style={{ fontWeight: "500", fontSize: "14px" }}>
             {Year.toString()} Jigar.
          </span>
          <p style={{ fontSize: "14px" }} className="ps-2">
            All rights reserved
          </p>
        </span>
      </div>
    </DeletedUsersWrapper>
  );
};
export default Deleted_Users;
