import AllRoutes from "./Routes/AllRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <div>
      <ToastContainer /> 
      <AllRoutes />
    </div>
  );
}

export default App;
