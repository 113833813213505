import React from "react";
import { SideDivWrapper } from "../GlobalStyle";
import jigar_white_logo from "../Assets/Logos/jigar_white_logo.png";
import {
  dashboard,
  report_logo,
  delete_logo,
  sign_out_option,
  activeUser,
  account,
  blockuser,
  JigartalaLogo,
} from "../Utils/Images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../Style/Sidebar.css";
import { useDispatch } from "react-redux";
import { Logout } from "../Store/Auth/action";

const SideDiv = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const getActive = (path, location) => {
    let current = location.pathname;
    if (current === path) {
      return "active";
    } else {
      return "inactive";
    }
  };
  return (
    <SideDivWrapper>
      <div className="appLogo pt-3">
        <img
          onClick={() => navigate("/jigar/users")}
          alt=""
          src={jigar_white_logo}
        />
      </div>

      <div className="linkButtons mt-2">
        <ul className="mt-3">
          <div>
            <Link className="dashboard_link" to="">
              <span
                className={`p-1 pt-2 pb-2 m-0 ps-2 ${getActive(
                  "/jigar",
                  location
                )}`}
              >
                <img alt="" className="link_Logo mt-1" src={dashboard} />
                <span className={` ms-2 link_Btn`}>Dashboard</span>
              </span>
            </Link>
          </div>
          <Link className="dashboard_link" to="users">
            <span
              className={`p-1 pt-2 pb-2 m-0 ps-2 ${getActive(
                "/jigar/users",
                location
              )}`}
            >
              <img alt="" className="link_Logo mt-1" src={account} />
              <span className="ms-2 link_Btn">All Users</span>
            </span>
          </Link>
          {/* <Link className="dashboard_link" to="jigartala-enabled">                        
            <span
              className={`p-1 pt-2 pb-2 m-0 ps-2 ${getActive(
                "/jigar/jigartala-enabled",
                location
              )}`}
            >
              <img alt="" className="link_Logo mt-1" src={JigartalaLogo} />
              <span className="ms-2 link_Btn">JigarTala</span>
            </span>
          </Link> */}
          {/* <Link className="dashboard_link" to="activeusers">
            <span
              className={`p-1 pt-2 pb-2 m-0 ps-2 ${getActive(
                "/jigar/activeusers",
                location
              )}`}
            >
              <img alt="" className="link_Logo mt-1" src={activeUser} />
              <span className="ms-2 link_Btn">Active Users</span>
            </span>
          </Link> */}
          <Link className="dashboard_link" to="reported">
            <span
              className={`p-1 pt-2 pb-2 m-0 ps-2 ${getActive(
                "/jigar/reported",
                location
              )}`}
            >
              <img alt="" className="link_Logo mt-1" src={report_logo} />
              <span className="ms-2 link_Btn">Reported Users</span>
            </span>
          </Link>
          <Link className="dashboard_link" to="blocked">
            <span
              className={`p-1 pt-2 pb-2 m-0 ps-2 ${getActive(
                "/jigar/blocked",
                location
              )}`}
            >
              <img alt="" className="link_Logo mt-1" src={blockuser} />
              <span className="ms-2 link_Btn">Blocked Users</span>
            </span>
          </Link>
          <Link className="dashboard_link" to="deleted">
            {" "}
            <span
              className={`p-1 pt-2 pb-2 m-0 ps-2 ${getActive(
                "/jigar/deleted",
                location
              )}`}
            >
              <img alt="" className="link_Logo mt-1" src={delete_logo} />
              <span className="ms-2 link_Btn">Deleted Users</span>
            </span>
          </Link>
          {/* <Link className="dashboard_link" to="signout">
            {" "}
            <div style={{ backgroundColor: "#222d32" }}>
              <span
                type="button"
                className={`p-1 pt-2 pb-2 m-0 ps-2 ${getActive(
                  "/jigar/signout",
                  location
                )}`}
                onClick={() => dispatch(Logout())}
              >
                <img alt="" className="link_Logo mt-1" src={sign_out_option} />
                <span className="ms-2 link_Btn">Sign Out</span>
              </span>
            </div>
          </Link> */}
          <Link className="dashboard_link" to="signout">
            {" "}
            <span
              className="p-1 pt-2 pb-2 m-0 ps-2 signOutBtn"
              onClick={() => dispatch(Logout())}
            >
              <img alt="" className="link_Logo mt-1" src={sign_out_option} />
              <span className="ms-2 link_Btn">Sign Out</span>
            </span>
          </Link>
        </ul>
      </div>
    </SideDivWrapper>
  );
};

export default SideDiv;

// const styleLink = styled.link;
