import styled from "styled-components";

export const getSignupType = (type) => {
  if (type === 1) {
    return "Facebook";
  }
  if (type === 2) {
    return "Apple";
  }
  return "Email";
};

export const getProfile = (photo) => {
  let image = "";
  photo?.map((val) => {
    if (val?.photo_position === 0) {
      image = val?.image_url;
    }
  });
  if (image !== "") {
    return image;
  } else {
    return photo[0]?.image_url;
  }
};

export const toastMessage = (val) => {
  return <Message>{val}</Message>;
};
const Message = styled.div`
  font-size: 13px;
`;
