/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { DashboardWrapper, DashboardHeader } from "../../GlobalStyle";
import DoubleScrollbar from "react-double-scrollbar";
import * as moment from "moment";

import {
  reportaction,
  dollar,
  people,
  sort,
  info,
  trash,
  defautlMale,
  sortAsc,
  sortDesc,
} from "../../Utils/Images";
import "../../Style/Dashboard.css";
import "../../Style/Dashboard_table.css";
import {
  blockUser,
  dashboardUsers,
  deleteUser,
  searchDashboard,
} from "../../ApiCrud";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getProfile,
  getSignupType,
  toastMessage,
} from "../Module/Functions/Functions";
import DeleteBlock from "../Components/DeleteBlock";
import JigarTalaModal from "../Components/JigarTalaModal";
import JigarOnFireModal from "../Components/JigarOnFireModal";
import SuperLikesModal from "../Components/SuperLikesModal";
import { SelectMultipleUsersActions } from "../../Utils/SelectMultipleUsersActions";
import MultipleDeleteModal from "../Components/MultipleDelete";
import { useRef } from "react";

const Dashboard2 = () => {
  const navigate = useNavigate();
  const [totalUsers, setTotalUsers] = useState();
  const [totalPurchase, setTotalPurchase] = useState();
  const [jigarShowModal, setJigarShowModal] = useState(false);
  const [superLikesShowModal, setSuperLikesShowModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [allusers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [parameter, setParameter] = useState({});
  const [searchVAl, setSearchVal] = useState("");
  const [limit, setLimit] = useState(100);
  const [page, setPage] = useState(1);
  const [duration, setDuration] = useState("");
  const [modalData, setModalData] = useState([]);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [multipleModal, setMultipleModal] = useState(false);
  const [multipleBlockModal, setMultipleBlockModal] = useState(false);
  const [sortParam, setSortParam] = useState({
    sortType: "",
    sortBasis: "",
    timeDuration: duration,
  });
  const [openActiveDeactive, setOpenActiveDeactive] = useState(false);
  const location = useLocation();
  const SelectAllRef = useRef(null);

  console.log(SelectAllRef?.current?.checked, "SelectAllRef");
  // Function For Get Listing Of Users
  const getDashboardUsers = async () => {
    setLoading(true);
    setAllUsers([]);
    let res = await dashboardUsers(limit, page, sortParam);
    if (res.statusCode === 200) {
      // console.log(res.user_details);
      setAllUsers(res?.user_details?.allUsers);
      setTotalPurchase(res?.user_details?.totalPurchase);
      setTotalUsers(res?.user_details?.totalUsers);
      setLoading(false);
    } else {
      toast.error(toastMessage(res.message));
      setLoading(false);
    }
  };

  // Function for sort icons change
  const srcSortImage = (Basis) => {
    if (Basis === sortParam.sortBasis) {
      if (sortParam.sortType === "ASC") {
        return sortAsc;
      } else {
        return sortDesc;
      }
    } else {
      return sort;
    }
  };

  // Function for joined date
  const getDate = (date) => {
    // console.log(date);
    if (date) {
      let update = new Date(date);

      return moment(update).format("MM/DD/YYYY");
    } else {
      return "---";
    }
  };

  // Function for JigarOnFire Date
  const dateofpurchase = (date) => {
    let update = new Date(date * 1000);
    if (!date) {
      return " ";
    }
    return moment(update).format("MM/DD/YYYY");
  };

  // Function for JigarTala Dates
  const getJigarDate = (date) => {
    let update;
    if (date.length > 10) {
      date = date.split(" ");
      update = new Date(date[0]);
    } else {
      // console.log(date);
      date = date.split("-");
      date = date.reverse().join("-");
      update = new Date(date);
    }

    return moment(update).format("MM/DD/YYYY");
  };

  // Function For JigarTala Status
  const jigarTalaStatus = (status) => {
    if (status === "active") {
      return "JigarTala";
    } else if (status === "inactive") {
      return "Jigar";
    } else {
      return "---";
    }
  };

  // Function For Navigate to EditProfile Page
  const handleEdit = (value) => {
    return navigate("/jigar/editprofile", {
      state: { data: value, path: location.pathname, from: "Dashboard" },
    });
  };

  // Function For Navigate to UserDetail Page
  const handleProfile = (ind) => {
    navigate("/jigar/userdetail", {
      state: { id: ind, path: location.pathname, from: "Dashboard" },
    });
  };

  // Function For Handle Delete OR Block And Unblock Actions For Users
  const handleDelete = async () => {
    setModalLoading(true);
    let id = parameter.id;
    let obj = {
      userIds: [id],
    };
    if (parameter.name === "Delete") {
      let res = await deleteUser(obj);

      if (res.statusCode === 200) {
        let update = [];
        allusers?.map((val) => {
          if (val.id !== id) {
            update.push(val);
          }
        });
        setAllUsers(update);
        setModalLoading(false);
        setOpenActiveDeactive(false);
        toast.success(toastMessage(res.message));
      } else {
        setModalLoading(false);
        toast.error(toastMessage(res.message));
      }
    } else {
      // let res;
      // let user = { userId: id };
      let res = await blockUser(obj);
      if (res.statusCode === 200) {
        setModalLoading(false);
        setOpenActiveDeactive(false);
        toast.success(toastMessage(res.message));
        getDashboardUsers();
      } else {
        setModalLoading(false);
        toast.error(toastMessage(res.message));
      }
    }
  };

  const Year = new Date().getFullYear();
  // Function For Delete Action Modal
  const Delete = (payload) => {
    setOpenActiveDeactive(true);
    let para = {
      name: "Delete",
      body: "this user",
      id: payload,
    };
    setParameter(para);
  };

  console.log(
    selectedRowsData,
    "selectedRowsDataselectedRowsDataselectedRowsData"
  );

  // Function For Block Action Modal
  const Block = (payload) => {
    setOpenActiveDeactive(true);
    let para = {
      name: "Block",
      body: "this user",
      id: payload,
    };
    setParameter(para);
  };

  // Function For Sorting Actions
  const getSortParam = (sortBasis) => {
    setSearchVal("");
    setPage(1);
    if (sortBasis === sortParam.sortBasis) {
      setSortParam({
        sortType: sortParam.sortType === "DESC" ? "ASC" : "DESC",
        sortBasis: sortBasis,
        timeDuration: duration,
      });
    } else {
      setSortParam({
        sortType: "DESC",
        sortBasis: sortBasis,
        timeDuration: duration,
      });
    }
  };
  const sortingDataFlip = (sortBasis) => {
    setSearchVal("");
    setPage(1);
    if (sortBasis === sortParam.sortBasis) {
      setSortParam({
        sortType: sortParam.sortType === "ASC" ? "DESC" : "ASC",
        sortBasis: sortBasis,
        timeDuration: duration,
      });
    } else {
      setSortParam({
        sortType: "ASC",
        sortBasis: sortBasis,
        timeDuration: duration,
      });
    }
  };
  // useEffect Hook
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (searchVAl.length === 0) {
      setPage(1);
      setSortParam({ sortBasis: "", sortType: "", duration: "" });
    }
  }, [searchVAl]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

    if (searchVAl.length === 0) {
      getDashboardUsers();
    }
    if (searchVAl.length > 0 && page > 1) {
      getSearchWord(searchVAl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, sortParam]);

  const loadDefault = async () => {
    setLoading(true);
    setPage(1);
    setSortParam({
      sortBasis: "",
      sortType: "",
      duration: "",
    });
  };
  // Function For Handle Search
  const handleSearch = async (e) => {
    e.preventDefault();
    setPage(1);
    setLoading(true);
    let res = await searchDashboard(limit, 1, {
      search: searchVAl,
    });
    if (res.statusCode === 200) {
      setAllUsers(res.user_details.allUsers);
      setLoading(false);
    } else {
      toast.error(toastMessage(res.message));
      setLoading(false);
    }
  };

  // Function For Search Page Two And Next Page
  const getSearchWord = async (word) => {
    setLoading(true);
    setAllUsers([]);
    let s = word;

    if (s) {
      let res = await searchDashboard(limit, page, {
        search: word,
      });
      if (res.statusCode === 200) {
        setAllUsers(res.user_details.allUsers);
        setLoading(false);
      } else {
        toast.error(toastMessage(res.message));
        setLoading(false);
      }
    }
    if (!s) {
      getDashboardUsers();
    }
  };

  const handleAddRem = (obj, type, all) => {
    if (!all) {
      console.log(type, "type");
      if (type) {
        if (selectedRowsData?.length > 0) {
          if (Array.isArray(selectedRowsData)) {
            if (selectedRowsData.find((el) => el?.id === obj?.id)) {
              return selectedRowsData.map((el) => {
                if (el.key === obj.key) {
                  setSelectedRowsData(obj);
                }
                setSelectedRowsData(el);
              });
            } else {
              setSelectedRowsData([...selectedRowsData, obj]);
            }
          } else {
            setSelectedRowsData([obj]);
          }
        } else {
          setSelectedRowsData([obj]);
        }
      } else {
        let arr = selectedRowsData.filter((el) => el?.id !== obj?.id);
        setSelectedRowsData(arr);
      }
    } else {
      if (type) {
        setSelectedRowsData(allusers);
      } else {
        setSelectedRowsData([]);
      }
    }
  };

  const handleMultipleDelete = async (type) => {
    setModalLoading(true);
    let id = parameter.id;
    let arr = [];
    Array.isArray(selectedRowsData) && selectedRowsData?.length > 0
      ? selectedRowsData?.map((val) => {
          arr.push(val?.id);
        })
      : arr.push(id);

    let obj = {
      userIds: arr,
    };
    if (multipleModal) {
      let res = await deleteUser(obj);

      if (res.statusCode === 200) {
        let update = [];
        allusers?.map((val) => {
          if (val.id !== id) {
            update.push(val);
          }
        });
        setAllUsers(update);
        setModalLoading(false);
        setOpenActiveDeactive(false);
        setMultipleModal(false);
        setSelectedRowsData([]);
        getDashboardUsers();
        toast.success(toastMessage(res.message));
      } else {
        setModalLoading(false);
        toast.error(toastMessage(res.message));
      }
    } else {
      // let res;
      // let user = { userId: id };
      let res = await blockUser(obj);
      if (res.statusCode === 200) {
        setModalLoading(false);
        setOpenActiveDeactive(false);
        setMultipleBlockModal(false);
        toast.success(toastMessage(res.message));
        setMultipleModal(false);
        setSelectedRowsData([]);
        getDashboardUsers();
      } else {
        setModalLoading(false);
        toast.error(toastMessage(res.message));
      }
    }
  };

  const getImageStyling = (value) => {
    if (value?.subscription_status == "active" && !value?.enableByAdmin) {
      if (value?.BasicInfo?.incognito_status) {
        return { border: "3px solid #D6B357", opacity: "0.6" };
      } else {
        return { border: "3px solid #D6B357" };
      }
    } else {
      if (value?.BasicInfo?.incognito_status) {
        return { opacity: "0.6" };
      } else {
        return {};
      }
    }
  };

  // console.log({ selectedRowsData });

  // Function For Get Total Purchase Amount Of JigarOnFire And SuperLikes
  const getTotalAmount = (val, type) => {
    let total = 0;
    val.map((amt) => {
      if (type === 1) {
        total += +amt.amount;
      } else {
        total += +amt.actual_amount;
      }
    });
    total = parseFloat(total).toFixed(2);
    return total;
  };

  return (
    <DashboardWrapper>
      {openActiveDeactive && (
        <DeleteBlock
          open={openActiveDeactive}
          close={() => setOpenActiveDeactive(false)}
          confirmSumbit={handleDelete}
          parameter={parameter}
          loading={modalLoading}
        />
      )}
      {multipleModal && (
        <MultipleDeleteModal
          open={multipleModal}
          close={() => setMultipleModal(false)}
          confirmSumbit={handleMultipleDelete}
          type={"Delete"}
          loading={modalLoading}
        />
      )}
      {multipleBlockModal && (
        <MultipleDeleteModal
          open={multipleBlockModal}
          close={() => setMultipleBlockModal(false)}
          confirmSumbit={handleMultipleDelete}
          type={"Block"}
          loading={modalLoading}
        />
      )}
      {jigarShowModal && (
        <JigarTalaModal
          show={jigarShowModal}
          onhide={() => setJigarShowModal(false)}
          data={modalData}
        />
      )}
      {showModal && (
        <JigarOnFireModal
          show={showModal}
          onhide={() => setShowModal(false)}
          data={modalData}
        />
      )}
      {superLikesShowModal && (
        <SuperLikesModal
          show={superLikesShowModal}
          onhide={() => setSuperLikesShowModal(false)}
          data={modalData}
        />
      )}
      <DashboardHeader className="d-flex mt-4 ">
        <div className="w-15">
          <h3 style={{ fontFamily: "Poppins" }} className="mt-3 ps-3">
            Dashboard
          </h3>
        </div>
        <div className="w-25 ms-5">
          <div style={{ cursor: "pointer" }} className="d-flex">
            <div className="col-md-4 d-flex justify-content-center p-3 user_group_logo">
              <img alt="" src={people} />
            </div>
            <div className="col-md-6 total_users_bg">
              <p className="mt-1 mb-0 fs-6">TOTAL USERS</p>
              {loading ? (
                <div className="wave">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                </div>
              ) : (
                <h4 className="m-0 text-start text-center">{totalUsers}</h4>
              )}
            </div>
          </div>
        </div>
        <div className="w-25">
          <div
            style={{ cursor: "pointer" }}
            className="d-flex justify-content-end"
          >
            <div className="col-md-4 d-flex justify-content-center p-3 dollar_sign_logo">
              <img alt="" src={dollar} />
              {/* <ion-icon name="heart-outline"></ion-icon> */}

              {/* <i class="icon ionicons ion-social-usd"></i> */}
            </div>
            <div className="col-md-6 total_users_bg">
              <p className="mt-1 mb-0 fs-6">TOTAL PURCHASE</p>
              {loading ? (
                <div className="wave">
                  <span className="dot"></span>
                  <span className="dot"></span>
                  <span className="dot"></span>
                </div>
              ) : (
                <h4 className="m-0 text-start text-center">
                  ${Math.floor(totalPurchase)}
                </h4>
              )}
            </div>
          </div>
        </div>
        <div className="w-25">
          <div className="d-flex justify-content-end mt-3">
            <Link className="" to="activeusers" state={{ from: "Dashboard" }}>
              <button
                style={{
                  backgroundColor: "#00c0ef",
                  color: "white",
                  cursor: "pointer",
                }}
                className="btn"
              >
                Active Users
              </button>
            </Link>
          </div>
        </div>
      </DashboardHeader>
      <div className="dashboard_users">
        <div
          className="d-flex mt-5 dashboard_row p-4 pb-2 ps-0"
          style={{
            top: "0",
            zIndex: "0 !important",
          }}
        >
          <div className="w-25 d-flex">
            <span
              style={{ fontSize: "15px", fontFamily: "Poppins", color: "#000" }}
              className="mt-1 ps-3"
            >
              Show
            </span>
            <select
              className="entries_option ps-2 ms-2"
              onChange={(e) => {
                setLimit(e.target.value);
                setSearchVal("");
                setPage(1);
              }}
            >
              {/* <option value="50">50</option> */}
              <option value="100">100</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
            </select>
            <span
              style={{ fontSize: "15px", fontFamily: "Poppins" }}
              className="mt-1 ms-2"
            >
              entries
            </span>
          </div>
          <div className=" w-25 d-flex justify-content-end">
            <form onSubmit={handleSearch}>
              <label style={{ fontSize: "15px", fontFamily: "Poppins" }}>
                Search :
              </label>
              <input
                autoFocus
                value={searchVAl}
                disabled={loading}
                onChange={(e) => setSearchVal(e.target.value)}
                className="ms-1 serach_users"
                type="text"
              />
            </form>
          </div>
          <div
            style={{ width: "15%" }}
            className="d-flex justify-content-end me-3"
          >
            {sortParam.sortBasis !== "" ? (
              <button
                style={{ color: "#3c8dbc" }}
                className="btn btn-sm btn-info fs text-white"
                onClick={() => loadDefault()}
              >
                Reset filters
              </button>
            ) : (
              ""
            )}
          </div>
          <div
            style={{ width: "35%" }}
            className="d-flex justify-content-center"
          >
            <select
              style={{ fontSize: "15px", fontFamily: "Poppins" }}
              className="filter_option ps-3 w-75"
              onChange={(e) => {
                let data = {
                  sortType: sortParam.sortType,
                  sortBasis: sortParam.sortBasis,
                  timeDuration: e.target.value,
                };
                setDuration(e.target.value);
                setSearchVal("");
                setPage(1);
                setSortParam(data);
              }}
            >
              <option value="">Total</option>
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="lastweek">Last week</option>
              <option value="lastmonth">Last month</option>
              <option value="lastyear">Last year</option>
              <option value="notActive_3">Not active last 3 months</option>
              <option value="notActive_5">Not active last 5 months</option>
            </select>
          </div>
        </div>

        {selectedRowsData?.length > 0 && (
          <SelectMultipleUsersActions
            handleDelete={handleMultipleDelete}
            setMultipleModal={setMultipleModal}
            setMultipleBlockModal={setMultipleBlockModal}
          />
        )}
        <div>
          <div>
            <div>
              <div>
                {loading ? (
                  <div className="LoaderWrapper">
                    <div className="BackgroundLoader"></div>
                  </div>
                ) : (
                  <div className="MainTableWrapper2">
                    <DoubleScrollbar>
                      <div
                        style={{ minHeight: "282px" }}
                        className="dashboard_table1 p-0"
                      >
                        <table>
                          <thead className="DashboardTableHeader">
                            <tr
                              className="customheader"
                              style={{
                                postion: "sticky",
                                top: "0",
                                zIndex: "4",
                              }}
                            >
                              <td
                                className="members text-center fs-4 pb-4"
                                colSpan="14"
                                align="center"
                              >
                                Member
                              </td>
                              <td
                                className="swipes fs-4 pb-4"
                                colSpan="3"
                                align="center"
                              >
                                Swipes
                              </td>
                              <td className="matches fs-4" align="center">
                                Match
                              </td>
                              <td
                                className="track fs-4 pb-4"
                                colSpan="3"
                                align="center"
                              >
                                Track
                              </td>
                              <td
                                className="jigartala fs-4 pb-4"
                                colSpan="4"
                                align="center"
                              >
                                JigarTala
                              </td>
                              <td
                                className="jigaronfire fs-4 pb-4"
                                colSpan="3"
                                align="center"
                              >
                                JigarOnFire
                              </td>
                              <td
                                className="superlikes fs-4 pb-4"
                                colSpan="7"
                                align="center"
                              >
                                SuperLikes
                              </td>
                            </tr>
                            <tr className="table_row">
                              {/* Members Heading */}
                              <th className="members">
                                <input
                                  type="checkbox"
                                  checked={
                                    allusers?.length == selectedRowsData?.length
                                  }
                                  onChange={(e) =>
                                    handleAddRem(null, e.target.checked, true)
                                  }
                                  // ref={SelectAllRef}
                                />
                              </th>
                              <th className="members text-center">
                                {/* <span className="span1 ps-2"> */}#
                                {/* </span> */}
                              </th>
                              <th className="members text-center">
                                <span className="">Thumbnail </span>
                              </th>
                              <th className="members text-center">
                                <span className="">Users</span>
                              </th>
                              <th className="members pt-4 pb-4">
                                <span
                                  style={{ whiteSpace: "nowrap" }}
                                  className="span1 d-flex justify-content-center"
                                >
                                  Last Active
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto ps-3"
                                    src={srcSortImage("lastActive")}
                                    onClick={() =>
                                      sortingDataFlip("lastActive")
                                    }
                                  />
                                </span>
                              </th>
                              <th className="members text-center">
                                <span className="span1">
                                  Joined
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("Date")}
                                    onClick={() => sortingDataFlip("Date")}
                                  />
                                </span>
                              </th>
                              <th className="members text-center">
                                <span className="span1">
                                  M/F
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("Gender")}
                                    onClick={() => sortingDataFlip("Gender")}
                                  />
                                </span>
                              </th>
                              <th className="members text-center">
                                <span className="span1">
                                  # of days
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("Days")}
                                    onClick={() => getSortParam("Days")}
                                  />
                                </span>
                              </th>
                              <th className="members text-center">
                                <span className="">Location</span>
                              </th>
                              <th className="members text-center">
                                <span className="span1">
                                  Device{" "}
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto ps-3"
                                    src={srcSortImage("device")}
                                    onClick={() => sortingDataFlip("device")}
                                  />
                                </span>
                              </th>
                              <th className="members text-center">
                                <span className="span1">
                                  Status{" "}
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto ps-3"
                                    src={srcSortImage("status")}
                                    onClick={() => sortingDataFlip("status")}
                                  />
                                </span>
                              </th>
                              <th className="members text-center">
                                <span className="span1">
                                  Age
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("Age")}
                                    onClick={() => getSortParam("Age")}
                                  />
                                </span>
                              </th>
                              <th className="members text-center">
                                <span className="">
                                  Login Type{" "}
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto ps-3"
                                    src={srcSortImage("loginType")}
                                    onClick={() => sortingDataFlip("loginType")}
                                  />
                                </span>
                              </th>
                              <th className="members text-center">
                                <span className="">Action</span>
                              </th>
                              {/* Swipe Heading */}
                              <th className="swipes">
                                <span className="span1">
                                  Swipe Left
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("leftSwipe")}
                                    onClick={() => getSortParam("leftSwipe")}
                                  />
                                </span>
                              </th>
                              <th className="swipes">
                                <span className="span1">
                                  Swipe Right
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("rightSwipe")}
                                    onClick={() => getSortParam("rightSwipe")}
                                  />
                                </span>
                              </th>
                              <th className="swipes">
                                <span className="span1">
                                  Total Swipes
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("totalSwipe")}
                                    onClick={() => getSortParam("totalSwipe")}
                                  />
                                </span>
                              </th>
                              {/* Matches Heading */}
                              <th className="matches">
                                <span className="span1">
                                  Matches
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("matches")}
                                    onClick={() => getSortParam("matches")}
                                  />
                                </span>
                              </th>
                              {/* <th className="track">
                            <span className="span1">
                              Number of App Usage(Total)
                              <img
                                alt=""
                                className="sort1 mt-auto mb-auto"
                                src={sort}
                              />
                            </span>
                          </th> */}
                              <th className="track">
                                <span className="span1">
                                  Reported users
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("reportedUsersCount")}
                                    onClick={() =>
                                      getSortParam("reportedUsersCount")
                                    }
                                  />
                                </span>
                              </th>
                              <th className="track">
                                <span className="span1">
                                  Was Disliked
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("disliked_count")}
                                    onClick={() =>
                                      getSortParam("disliked_count")
                                    }
                                  />
                                </span>
                              </th>
                              <th className="track">
                                <span className="span1">
                                  Was Liked
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("liked_count")}
                                    onClick={() => getSortParam("liked_count")}
                                  />
                                </span>
                              </th>
                              {/* JigarTala Heading */}
                              <th className="jigartala text-center">
                                <span className="span1 text-center">
                                  Status
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("jigartalaStatus")}
                                    onClick={() =>
                                      sortingDataFlip("jigartalaStatus")
                                    }
                                  />
                                </span>
                              </th>
                              <th className="jigartala">
                                <span className="span1">
                                  JigarTala $
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("jigartalaPrice")}
                                    onClick={() =>
                                      getSortParam("jigartalaPrice")
                                    }
                                  />
                                </span>
                              </th>
                              <th className="jigartala">
                                <span className="span1">
                                  Purchased (Date)
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("jigartalaPurchaseDate")}
                                    onClick={() =>
                                      getSortParam("jigartalaPurchaseDate")
                                    }
                                  />
                                </span>
                              </th>
                              <th className="jigartala">
                                <span className="span1">
                                  End (Date)
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("jigarTalaEnddate")}
                                    onClick={() =>
                                      getSortParam("jigarTalaEnddate")
                                    }
                                  />
                                </span>
                              </th>
                              {/* JigarOnFire Heading */}
                              <th className="jigaronfire">
                                <span className="span1">
                                  Amount $
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("FireAmount")}
                                    onClick={() => getSortParam("FireAmount")}
                                  />
                                </span>
                              </th>
                              <th className="jigaronfire">
                                <span className="span1">
                                  Purchase Date
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("FireDate")}
                                    onClick={() => getSortParam("FireDate")}
                                  />
                                </span>
                              </th>
                              <th className="jigaronfire">
                                <span className="span1">
                                  Type
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("FirePurchaseType")}
                                    onClick={() =>
                                      getSortParam("FirePurchaseType")
                                    }
                                  />
                                </span>
                              </th>
                              {/* SuperLikes Heading */}
                              <th className="superlikes">
                                <span className="span1">
                                  SuperLikes Purchased
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage(
                                      "totalSuperLikePurchased"
                                    )}
                                    onClick={() =>
                                      getSortParam("totalSuperLikePurchased")
                                    }
                                  />
                                </span>
                              </th>

                              <th className="superlikes">
                                <span className="span1">
                                  SuperLikes $
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage(
                                      "superLikePurchaseAmount"
                                    )}
                                    onClick={() =>
                                      getSortParam("superLikePurchaseAmount")
                                    }
                                  />
                                </span>
                              </th>
                              <th className="superlikes">
                                <span className="span1">
                                  Purchased (Date)
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("superLikePurchaseDate")}
                                    onClick={() =>
                                      getSortParam("superLikePurchaseDate")
                                    }
                                  />
                                </span>
                              </th>
                              <th className="superlikes">
                                <span className="span1">
                                  Paid SuperLikes (Used)
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("paidSuperLikeUsed")}
                                    onClick={() =>
                                      getSortParam("paidSuperLikeUsed")
                                    }
                                  />
                                </span>
                              </th>
                              <th className="superlikes">
                                <span className="span1">
                                  Paid SuperLikes (Left)
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("paidSuperLikesLeft")}
                                    onClick={() =>
                                      getSortParam("paidSuperLikesLeft")
                                    }
                                  />
                                </span>
                              </th>
                              <th className="superlikes">
                                <span className="span1">
                                  Free SuperLikes (Used)
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto"
                                    src={srcSortImage("freeSuperLikeUsed")}
                                    onClick={() =>
                                      getSortParam("freeSuperLikeUsed")
                                    }
                                  />
                                </span>
                              </th>
                              <th className="superlikes">
                                <span className="span1 me-3">
                                  Free SuperLikes (Left)
                                  <img
                                    alt=""
                                    className="sort1 mt-auto mb-auto ml-n1"
                                    src={srcSortImage("freeSuperLikesLeft")}
                                    onClick={() =>
                                      getSortParam("freeSuperLikesLeft")
                                    }
                                  />
                                </span>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {allusers?.length > 0 ? (
                              allusers?.map((value, index) => {
                                return (
                                  <tr className="">
                                    {/* Members Start Here */}
                                    <td className="tableData">
                                      <input
                                        type="checkbox"
                                        checked={
                                          selectedRowsData?.length > 0 &&
                                          selectedRowsData?.some(
                                            (el) => el?.id == value?.id
                                          )
                                        }
                                        onChange={(e) => {
                                          handleAddRem(value, e.target.checked);
                                          if (!e.target.checked) {
                                            SelectAllRef.current.checked = false;
                                          }
                                        }}
                                      />
                                    </td>
                                    <td className="tableData">
                                      {" "}
                                      {page > 1
                                        ? limit * (page - 1) + (1 + index)
                                        : index + 1}
                                    </td>
                                    <td className="tableData">
                                      {value?.Photos?.length > 0 ? (
                                        <img
                                          // style={{objectFit: "fill"}}
                                          style={getImageStyling(value)}
                                          alt=""
                                          className="tableDataimg ms-2"
                                          src={getProfile(value?.Photos)}
                                        />
                                      ) : (
                                        <img
                                          style={{ objectFit: "cover" }}
                                          alt=""
                                          className="tableDataimg ms-2"
                                          src={defautlMale}
                                        />
                                      )}
                                    </td>
                                    <td
                                      style={{ cursor: "pointer" }}
                                      className="tableData text-hover-primary "
                                      onClick={() => handleEdit(value)}
                                    >
                                      <span
                                        style={{
                                          borderBottom: "1px solid #ccc",
                                        }}
                                      >
                                        {value?.BasicInfo?.name
                                          ? value?.BasicInfo?.name
                                          : "---"}
                                      </span>
                                    </td>
                                    <td className="tableData ps-2 pe-2">
                                      {getDate(value?.last_active)}
                                      <br />
                                      {value?.last_active &&
                                        moment(value?.last_active).format(
                                          "HH:mm"
                                        )}
                                    </td>
                                    <td className="tableData ps-2 pe-2">
                                      {getDate(value.createdAt)}
                                    </td>
                                    <td className="tableData">
                                      {value?.BasicInfo?.gender
                                        ? value?.BasicInfo?.gender
                                        : "---"}
                                    </td>
                                    <td className="tableData">{value?.Days}</td>
                                    <td className="tableData">
                                      {value?.BasicInfo
                                        ? value?.BasicInfo.current_location
                                          ? value?.BasicInfo
                                              ?.current_location === "null"
                                            ? "---"
                                            : value?.BasicInfo?.current_location
                                          : "---"
                                        : "---"}
                                    </td>
                                    <td className="tableData">
                                      {value?.platform
                                        ? value?.platform
                                        : "---"}
                                    </td>
                                    <td className="tableData">
                                      {value?.is_profile_completed
                                        ? "Active"
                                        : "Inactive"}
                                    </td>
                                    <td className="tableData">
                                      {value?.BasicInfo?.age
                                        ? value?.BasicInfo?.age
                                        : "---"}
                                    </td>
                                    <td className="tableData">
                                      {" "}
                                      {value?.signup_type === 0
                                        ? `${
                                            value?.country_code !== null
                                              ? `(${value?.country_code})`
                                              : ""
                                          }${value?.mobile_number}`
                                        : getSignupType(value?.signup_type)}
                                    </td>
                                    {/* Members End here */}

                                    {/* Action Start here */}
                                    <td className="tableData ps-2 pe-3">
                                      <div className="d-flex action_btn_group">
                                        {/* <button
                                          onClick={() => handleEdit(value)}
                                          className="action_edit"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit"
                                        >
                                          <img alt="" src={draw} />
                                        </button> */}
                                        <button
                                          onClick={() =>
                                            handleProfile(value.id)
                                          }
                                          className="action_info"
                                        >
                                          {" "}
                                          <img alt="" src={info} />
                                        </button>
                                        <button
                                          onClick={() => Delete(value?.id)}
                                          className="action_delete"
                                        >
                                          {" "}
                                          <img alt="" src={trash} />
                                        </button>
                                        <button
                                          onClick={() => Block(value?.id)}
                                          className="action_report"
                                        >
                                          {" "}
                                          <img alt="" src={reportaction} />
                                        </button>
                                      </div>
                                    </td>
                                    {/* Action End  here*/}

                                    {/* Swipes Start here */}
                                    <td className="tableData">
                                      {value?.leftSwipe
                                        ? value?.leftSwipe
                                        : "0"}
                                    </td>
                                    <td className="tableData">
                                      {value?.rightSwipe
                                        ? value?.rightSwipe
                                        : "0"}
                                    </td>
                                    <td className="tableData">
                                      {value?.totalSwipe
                                        ? value?.totalSwipe
                                        : "0"}
                                    </td>
                                    {/* Swipes End  here*/}

                                    {/* Match Start here */}
                                    <td className="tableData">
                                      {value?.matches ? value?.matches : "0"}
                                    </td>
                                    {/* Match End  here*/}

                                    {/* Track Start Here */}

                                    {/* <td className="tableData">
                                  Number of App Usage(Total)
                                </td> */}
                                    <td className="tableData">
                                      {value?.reportedUsersCount
                                        ? value?.reportedUsersCount
                                        : "0"}
                                    </td>
                                    <td className="tableData">
                                      {value?.disliked_count
                                        ? value?.disliked_count
                                        : "0"}
                                    </td>
                                    <td className="tableData">
                                      {value?.liked_count
                                        ? value?.liked_count
                                        : "0"}
                                    </td>
                                    {/* Track End Here */}

                                    {/* JigarTala Start Here */}
                                    <td className="tableData">
                                      {jigarTalaStatus(
                                        value?.subscription_status
                                      )}
                                    </td>
                                    <td
                                      className="tableData"
                                      onClick={() => {
                                        if (value?.Subscriptions?.length > 1) {
                                          setJigarShowModal(true);
                                          setModalData(value?.Subscriptions);
                                        }
                                      }}
                                    >
                                      {value?.Subscriptions.length > 1 ? (
                                        <span
                                          style={{
                                            border: "1px solid #ccc",
                                            padding: "1px 5px",
                                            borderRadius: "3px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          $
                                          {value?.Subscriptions[0].total_amount}
                                        </span>
                                      ) : value?.Subscriptions.length === 1 ? (
                                        `$${value?.Subscriptions[0].total_amount}`
                                      ) : (
                                        "$0"
                                      )}
                                    </td>
                                    <td className="tableData">
                                      {value?.Subscriptions.length > 0
                                        ? getJigarDate(
                                            value?.Subscriptions[0]
                                              ?.purchase_date
                                          )
                                        : "---"}
                                    </td>
                                    <td className="tableData">
                                      {value?.Subscriptions.length > 0
                                        ? getJigarDate(
                                            value?.Subscriptions[0]?.end_date
                                          )
                                        : "---"}
                                    </td>
                                    {/* JigarTala Ends Here */}

                                    {/* JigarOnFire Start Here */}
                                    <td
                                      className="tableData"
                                      onClick={() => {
                                        if (
                                          value?.jigarOnFireTransactions
                                            ?.length > 1
                                        ) {
                                          setShowModal(true);
                                          setModalData(
                                            value?.jigarOnFireTransactions
                                          );
                                        }
                                      }}
                                    >
                                      {value?.jigarOnFireTransactions?.length >
                                      1 ? (
                                        <span
                                          style={{
                                            border: "1px solid #ccc",
                                            padding: "1px 5px",
                                            borderRadius: "3px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          $
                                          {getTotalAmount(
                                            value?.jigarOnFireTransactions,
                                            2
                                          )}
                                        </span>
                                      ) : value?.jigarOnFireTransactions
                                          ?.length === 1 ? (
                                        `$${getTotalAmount(
                                          value?.jigarOnFireTransactions,
                                          2
                                        )}`
                                      ) : (
                                        "$0"
                                      )}
                                    </td>
                                    <td className="tableData">
                                      {value?.jigarOnFireTransactions?.length >
                                      0
                                        ? dateofpurchase(
                                            value?.jigarOnFireTransactions[0]
                                              ?.dateofpurchase
                                          )
                                        : "---"}
                                    </td>
                                    <td className="tableData">
                                      {value?.jigarOnFireTransactions?.length >
                                      0
                                        ? value?.jigarOnFireTransactions[0]
                                            ?.purchase_type === 2
                                          ? "Worldwide"
                                          : value?.jigarOnFireTransactions[0]
                                              ?.purchase_type === 1
                                          ? "Local"
                                          : "---"
                                        : "---"}
                                      {/* {value.jigarOnFireTransactions.length > 0
                                      ? value.jigarOnFireTransactions[0]
                                          ?.purchase_type === 2
                                        ? "Worldwide"
                                        : value.jigarOnFireTransactions[0]
                                            ?.purchase_type === 1
                                        ? "Local"
                                        : ""
                                      : null} */}
                                    </td>
                                    {/* JigarOnFire Ends Here */}

                                    {/* SuperLikes Start Here */}
                                    <td className="tableData">
                                      {value?.totalpaidsuperlike
                                        ? value?.totalpaidsuperlike
                                        : "0"}
                                    </td>
                                    <td
                                      className="tableData"
                                      onClick={() => {
                                        if (
                                          value?.SuperLikesTransaction.length >
                                          1
                                        ) {
                                          setSuperLikesShowModal(true);
                                          setModalData(
                                            value?.SuperLikesTransaction
                                          );
                                        }
                                      }}
                                    >
                                      {value?.SuperLikesTransaction.length >
                                      1 ? (
                                        <span
                                          style={{
                                            border: "1px solid #ccc",
                                            padding: "1px 5px",
                                            borderRadius: "3px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          $
                                          {getTotalAmount(
                                            value?.SuperLikesTransaction,
                                            1
                                          )}
                                        </span>
                                      ) : value?.SuperLikesTransaction
                                          .length === 1 ? (
                                        `$${getTotalAmount(
                                          value?.SuperLikesTransaction,
                                          1
                                        )}`
                                      ) : (
                                        "$0"
                                      )}
                                    </td>
                                    <td className="tableData">
                                      {value?.SuperLikesTransaction
                                        ? getDate(
                                            value?.SuperLikesTransaction[0]
                                              ?.createdAt
                                          )
                                        : "---"}
                                    </td>
                                    <td className="tableData">
                                      {value?.paidSuperLikeUsed
                                        ? value?.paidSuperLikeUsed
                                        : 0}
                                    </td>
                                    <td className="tableData">
                                      {value?.paidSuperLikesLeft !== null &&
                                      value?.paidSuperLikesLeft > 0
                                        ? value?.paidSuperLikesLeft
                                        : 0}
                                    </td>
                                    <td className="tableData">
                                      {value?.freeSuperLikeUsed
                                        ? value?.freeSuperLikeUsed
                                        : 0}
                                    </td>
                                    <td className="tableData">
                                      {/* {value?.freeSuperLikesLeft !== null &&
                                      value?.freeSuperLikesLeft > 0
                                        ? value?.freeSuperLikesLeft
                                        : value?.superLikeCount > 0
                                        ? value?.superLikeCount
                                        : 0} */}
                                      {value?.freeSuperLikesLeft !== null
                                        ? value?.freeSuperLikesLeft
                                        : "0"}
                                    </td>
                                    {/* SuperLikes Ends Here */}
                                  </tr>
                                );
                              })
                            ) : (
                              <div
                                style={{
                                  position: "absolute",
                                  width: "95%",
                                  paddingTop: "5%",
                                }}
                                className="d-flex justify-content-center text-success ps-3 fs-2"
                              >
                                No Data
                              </div>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </DoubleScrollbar>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex w-100 pt-1 ps-4"
        style={{
          display: "none",
          // position: "fixed",
          // bottom: "40px"
          backgroundColor: "#ecf0f5",
        }}
      >
        <div className="w-50 p-0" style={{ height: "max-content" }}>
          <p className="m-0" style={{ fontSize: "14px" }}>
            Showing {page > 1 ? (page - 1) * limit + 1 : 1} to{" "}
            {page > 1
              ? limit * page
              : allusers?.length >= limit
              ? limit
              : allusers?.length}{" "}
            of {totalUsers} entries
          </p>
        </div>
        <div className="w-50" style={{ height: "max-content" }}>
          <div className="d-flex justify-content-end">
            <div className="pagination">
              <ul>
                {page > 1 && (
                  <li
                    onClick={() => {
                      if (!loading) {
                        setPage(page - 1);
                      }
                    }}
                  >
                    Previous
                  </li>
                )}
                {allusers?.length <= limit && page > 1 && (
                  <li
                    onClick={() => {
                      if (!loading) {
                        setPage(page - 1);
                      }
                    }}
                  >
                    {allusers?.length <= limit ? page - 1 : ""}
                  </li>
                )}
                {/* <li>{page > 0 ? page - 1 : null}</li> */}
                <li className="active">{page}</li>
                {allusers?.length >= limit && (
                  <li
                    onClick={() => {
                      if (!loading) {
                        setPage(page + 1);
                      }
                    }}
                  >
                    {allusers?.length >= limit ? page + 1 : ""}
                  </li>
                )}

                {allusers?.length >= limit && (
                  <li
                    onClick={() => {
                      if (!loading) {
                        setPage(page + 1);
                      }
                    }}
                  >
                    {allusers?.length >= limit ? "Next" : ""}
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-center "
        style={{
          backgroundColor: "#ecf0f5",
        }}
      >
        <span className="d-flex justify-content-center">
          <span style={{ fontWeight: "500", fontSize: "14px" }}>
            {Year.toString()} Jigar.
          </span>
          <p style={{ fontSize: "14px" }} className="ps-2">
            All rights reserved
          </p>
        </span>
      </div>
    </DashboardWrapper>
  );
};

export default Dashboard2;
